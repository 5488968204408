import React, { FC } from 'react';
import { PostHeader } from '../../assets/icons';
import { useIsMedium, useIsSmall } from '../../hooks/mediaQuery';
import '../../styles/pages/PostSection.scss';
import SlideLoop from '../MintPage/components/SliderLoop';
import { default as posts } from './../../assets/images/posts';

const Post: FC = () => {
  const isSmall = useIsSmall();
  const isMedium = useIsMedium();
  const numRow = isMedium ? 4 : isSmall ? 3 : 1;
  const postRows =
    numRow === 4
      ? [
          posts.slice(0, 3),
          posts.slice(4, 7),
          posts.slice(7, 10),
          posts.slice(10, 13),
        ]
      : numRow === 3
      ? [posts.slice(0, 5), posts.slice(5, 10), posts.slice(9, 13)]
      : [[]];

  return (
    <div className="Post">
      <div className="Post__icon-left">
        <img src={'/images/home-page/icon-side-left.png'} />
      </div>
      <div className="Post__cloud-left">
        <img src="/images/home-page/cloud-left.png" />
      </div>
      <div className="Post__cloud-right">
        <img src="/images/home-page/cloud-right.png" />
      </div>
      <div className="Post__title">
        <img src={PostHeader} />
      </div>

      {numRow > 1 ? (
        <div className="Post__container" style={{ height: '722px' }}>
          <div className="Post__container__mask--top"></div>
          <div className="Post__container__mask--bottom"></div>

          {postRows.map((postRow, index) => {
            return (
              <SlideLoop
                axis="vertical"
                animationDuration="20s"
                direction={index % 2 === 0 ? 'clockwise' : 'reverse'}
              >
                <div key={index} className="Post__container__row">
                  {postRow.map((post: any, postIndex: number) => {
                    return (
                      <a href={post.link} key={postIndex} target={'_blank'}>
                        <img
                          className="Post__container__row__item"
                          src={post.image}
                        />
                      </a>
                    );
                  })}
                </div>
              </SlideLoop>
            );
          })}
        </div>
      ) : (
        <SlideLoop
          axis="horizontal"
          animationDuration="25s"
          direction={'reverse'}
        >
          <div className="Post__container--horizontal">
            {posts.map((post, index) => {
              return (
                <a href={post.link} key={index} target={'_blank'}>
                  <img
                    className="Post__container__row__item"
                    src={post.image}
                  />
                </a>
              );
            })}
          </div>
        </SlideLoop>
      )}

      <div className="Post__icon-right">
        <img src={'/images/home-page/icon-side-right.png'} />
      </div>
    </div>
  );
};

export default Post;
