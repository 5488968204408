import { createSlice } from '@reduxjs/toolkit';

export type GameState = {
  name: string;
};

const initialState: GameState = {
  name: 'GameEggCollector',
};

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setGame: (state, action) => {
      const { gameName } = action.payload;
      state.name = gameName;
    },
  },
});

export const { setGame } = gameSlice.actions;

export default gameSlice.reducer;
