import Modal from 'react-modal';
import React, { FC, ReactNode } from 'react';
import '../styles/modals/Modal.scss';
import { useMediaQuery } from 'react-responsive';

interface IBaseModal {
  children: ReactNode;
  open: boolean;
  isHideOverlay?: boolean;
  className?: string;
  zIndex?: number;
}

const BaseModal: FC<IBaseModal> = ({
  className,
  children,
  open,
  isHideOverlay,
  zIndex,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Modal
      isOpen={open}
      className={className}
      ariaHideApp={false}
      portalClassName="modal"
      style={{
        overlay: {
          background: !isHideOverlay ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
          backdropFilter: 'blur(7.5px)',
          zIndex: zIndex || 998,
        },
        content: {
          width: isMobile ? '311px' : '384px',
          height: 'auto',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          padding: '24px',
          transform: 'translate(-50%, -50%)',
        },
      }}
    >
      {children}
    </Modal>
  );
};

export default BaseModal;
