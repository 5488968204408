import React, { FC } from 'react';
import '../../styles/pages/MintPage.scss';
import { pacmanNftGen0, pacmanNftGen1 } from '../../assets/images/pacman';

const Discover: FC = () => {
  return (
    <div className={'MintPage__discover'}>
      <SlideLoop animationDuration="25s" direction="clockwise">
        <div className={'MintPage__discover__gen'}>
          {pacmanNftGen1.slice(0, 20).map((el, index) => {
            return <img key={index} src={el} />;
          })}
        </div>
      </SlideLoop>

      <SlideLoop animationDuration="30s" direction="reverse">
        <div className={'MintPage__discover__gen'}>
          {pacmanNftGen0.slice(0, 20).map((el, index) => {
            return <img key={index} src={el} />;
          })}
        </div>
      </SlideLoop>
    </div>
  );
};

const SlideLoop: FC<{
  animationDuration: string;
  direction: 'clockwise' | 'reverse';
  children: React.ReactNode;
}> = ({ animationDuration, children, direction }) => {
  return (
    <div
      className="SlideLoop__container"
      style={{
        justifyContent: direction === 'clockwise' ? 'flex-start' : 'flex-end',
      }}
    >
      <div
        className="SlideLoop__container__child1"
        style={{
          animationDuration,
          animationName:
            direction === 'clockwise' ? 'slide-loop' : 'slide-loop-reverse',
        }}
      >
        {children}
      </div>

      <div
        className="SlideLoop__container__child2"
        style={{
          animationDuration,
          animationName:
            direction === 'clockwise' ? 'slide-loop' : 'slide-loop-reverse',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Discover;
