import React, { FC, useRef } from 'react';
import '../../../styles/pages/SliderLoop.scss';

const SlideLoop: FC<{
  animationDuration: string;
  direction: 'clockwise' | 'reverse';
  axis: 'vertical' | 'horizontal';
  children: React.ReactNode;
}> = ({ animationDuration, children, direction, axis }) => {
  const ref1 = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);

  const stopSlide = () => {
    if (ref1.current) {
      ref1.current.style.animationPlayState = 'paused';
    }
    if (ref2.current) {
      ref2.current.style.animationPlayState = 'paused';
    }
  };

  const runSlide = () => {
    if (ref1.current) {
      ref1.current.style.animationPlayState = 'running';
    }
    if (ref2.current) {
      ref2.current.style.animationPlayState = 'running';
    }
  };

  return (
    <div
      className="SlideLoop__container"
      style={{
        flexDirection: axis === 'vertical' ? 'column' : 'row',
        justifyContent: direction === 'clockwise' ? 'flex-start' : 'flex-end',
        ...(axis === 'horizontal' ? { width: '100vw' } : {}),
      }}
    >
      <div
        ref={ref1}
        className="SlideLoop__container__child1"
        onMouseEnter={stopSlide}
        onMouseLeave={runSlide}
        style={{
          animationDuration,
          animationName:
            (direction === 'clockwise' ? 'slide-loop' : 'slide-loop-reverse') +
            `--${axis}`,
        }}
      >
        {children}
      </div>

      <div
        ref={ref2}
        className="SlideLoop__container__child2"
        onMouseEnter={stopSlide}
        onMouseLeave={runSlide}
        style={{
          animationDuration,
          animationName:
            (direction === 'clockwise' ? 'slide-loop' : 'slide-loop-reverse') +
            `--${axis}`,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default SlideLoop;
