import BaseRequest from './BaseRequest';
import config from '../config';

export default class EggCollectionRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.baseUrlApi;
  }

  getLeaderBoard(payload: any) {
    const url = `/egg-collector/leader-board`;
    return this.get(url, payload);
  }

  getRecentBattle(payload: any) {
    const url = `/egg-collector/recent-battle`;
    return this.get(url, payload);
  }

  getMyBattle(payload: any) {
    const url = `/egg-collector/my-battle`;
    return this.get(url, payload);
  }
}
