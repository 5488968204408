import BaseModal from './BaseModal';
import React, { FC } from 'react';
import '../styles/modals/Modal.scss';
import { IconClose, IconCopy } from '../assets/icons';
import { useDispatch } from 'react-redux';
import { setIsOpenModalConnectWallet } from '../store/user';
import { SUPPORTED_WALLETS } from '../constants';
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import { formatShortAddress } from '../utils/format';
import { copyToClipboard } from '../utils/helper';

interface IModalConnectWallet {
  open: boolean;
}

const ModalConnectWallet: FC<IModalConnectWallet> = ({ open }) => {
  const dispatch = useDispatch();

  const connectorWallet = SUPPORTED_WALLETS['METAMASK'];

  const { activate, error, account, deactivate } = useWeb3React();

  const onConnect = () => {
    if (!window.ethereum) {
      window.open('https://metamask.io/', '_blank');
      return;
    }

    if (connectorWallet.connector) {
      try {
        activate(connectorWallet.connector).then();
        dispatch(setIsOpenModalConnectWallet(false));
      } catch (e) {
        console.error(e);
      }
    }
  };

  const onDisconnect = async () => {
    try {
      await deactivate();
      dispatch(setIsOpenModalConnectWallet(false));
    } catch (e) {
      console.error(e);
    }
  };

  const _renderContent = () => {
    if (error) {
      return (
        <div className="modal__content">
          <div className="modal__content-title">
            {error instanceof UnsupportedChainIdError
              ? 'Wrong Network'
              : 'Error connecting'}
          </div>

          <div className="modal__content-description">
            {error instanceof UnsupportedChainIdError
              ? 'Please connect to the appropriate Blast network.'
              : 'Error connecting. Try refreshing the page.'}
          </div>
        </div>
      );
    }

    if (account) {
      return (
        <div className="modal__content">
          <div className="modal__content-title">Account Details</div>

          <div className="box-wallet">
            <div className="box-wallet__content">
              <div>Connected with MetaMask</div>
              <div className="address">
                {formatShortAddress(account)}
                <div onClick={() => copyToClipboard(account)}>
                  <IconCopy />
                </div>
              </div>
              <div className="disconnect" onClick={onDisconnect}>
                Disconnect
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="modal__content">
        <div className="modal__content-title">Connect Wallet</div>

        <div className="wallet" onClick={onConnect}>
          <div>
            {!window.ethereum ? 'Install MetaMask' : connectorWallet.name}
          </div>
          <div>
            <img src={`/images/wallet/${connectorWallet.iconName}`} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <BaseModal open={open} isHideOverlay>
      <div className="modal-connect-wallet">
        <div
          className="modal__icon-close"
          onClick={() => dispatch(setIsOpenModalConnectWallet(false))}
        >
          <IconClose />
        </div>

        {_renderContent()}
      </div>
    </BaseModal>
  );
};

export default ModalConnectWallet;
