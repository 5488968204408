import BaseModal from './BaseModal';
import React, { FC, useState } from 'react';
import '../styles/modals/Modal.scss';
import { IconClose } from '../assets/icons';
import { isNumberDecimalAfter } from '../utils/helper';

interface IModalSlippage {
  open: boolean;
  onClose: () => void;
  onSetSlippage: (value: string) => void;
  slippage: string;
}

const SLIPPAGE = ['100', '500', '700'];

const ModalSlippage: FC<IModalSlippage> = ({
  open,
  onClose,
  slippage,
  onSetSlippage,
}) => {
  const [slippageCustom, setSlippageCustom] = useState<string>('');

  return (
    <BaseModal open={open} isHideOverlay>
      <div>
        <div className="modal__icon-close" onClick={onClose}>
          <IconClose />
        </div>

        <div className="modal__content">
          <div className="modal__content-title">Transaction Settings</div>

          <div className="modal__slippage">
            <div>Slippage tolerance</div>
            <div className="modal__slippage-options">
              {SLIPPAGE.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`${
                      item === slippage ? 'selected' : ''
                    } modal__slippage-option`}
                    onClick={() => onSetSlippage(item)}
                  >
                    {+item / 100}%
                  </div>
                );
              })}

              <div className="modal__slippage-input">
                <input
                  placeholder={Number(+slippage / 100).toFixed(2)}
                  value={slippageCustom}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (isNumberDecimalAfter(value) || value === '') {
                      setSlippageCustom(value);
                      onSetSlippage((Number(value) * 100).toString());
                    }
                  }}
                />
                %
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default ModalSlippage;
