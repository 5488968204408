import React, { useCallback, useMemo, useState } from 'react';
import config from '../../../config';
import { useMintNftGen0Callback } from '../../../hooksUniswap/useMintNftCallback';
import '../../../styles/pages/MintPage.scss';
import CountDownBox from './CountdownBox';
import {
  ConnectWalletBtn,
  EPhaseStatus,
  MintBtn,
  NotWhitelistedBtn,
} from '../Mint';
import PhaseStatus from './PhaseStatus';
import { ModalTransactionProcess } from '../../../modals';
import { useActiveWeb3React } from '../../../hooksUniswap';
import { setIsOpenModalConnectWallet } from '../../../store/user';
import { useDispatch } from 'react-redux';
import About from './About';
import rf from '../../../services/RequestFactory';
import { useEffectUnsafe } from '../../../hooks/useEffectUnsafe';
import { useGen0NftContract } from '../../../hooksUniswap/useContract';
import moment from 'moment';

const GEN0_NFT_CONTRACT_ADDRESS = config.contracts.gen0_nft_contract;

const Phase2: React.FC<{
  phaseStatus: EPhaseStatus;
  dueDate: number;
}> = ({ phaseStatus, dueDate }) => {
  const [isShowModalTransaction, setIsShowModalTransaction] =
    useState<boolean>(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState<boolean>(false);
  const [txHashInfo, setTxHashInfo] = useState<{
    hash: string;
    errorMessage?: string;
  }>();
  const [signature, setSignature] = useState<string>('');
  const [isMinted, setIsMinted] = useState<boolean>(true);

  const dispatch = useDispatch();
  const { account } = useActiveWeb3React();

  const onConnectWallet = () => {
    dispatch(setIsOpenModalConnectWallet(true));
  };

  const nftContract = useGen0NftContract(GEN0_NFT_CONTRACT_ADDRESS);

  const checkMintedNFT = useCallback(async () => {
    if (!account) return 0;
    const isMintedNFT = await nftContract?.mintedAddress(account);
    setIsMinted(isMintedNFT);
  }, [account]);

  useEffectUnsafe(() => {
    checkMintedNFT().then();
  }, [account]);

  const [mintCallback] = useMintNftGen0Callback(
    GEN0_NFT_CONTRACT_ADDRESS,
    signature,
  );

  const onMint = async () => {
    const onMintHandle = () => {
      return mintCallback()
        .then(async (hash) => {
          await new Promise((r) => setTimeout(r, 5 * config.block_time * 1000));
          await checkMintedNFT().then();
          setIsLoadingConfirm(false);
          setTxHashInfo({ hash });
        })
        .catch((error) => {
          setIsLoadingConfirm(false);
          setTxHashInfo((prev: any) => ({
            ...prev,
            errorMessage: error.message,
          }));
        });
    };

    setIsLoadingConfirm(true);
    setIsShowModalTransaction(true);

    return onMintHandle();
  };

  const disableMint = useMemo(() => {
    return !account || phaseStatus !== EPhaseStatus.LIVE || isMinted;
  }, [account, phaseStatus, isMinted]);

  const getSignatureGen0 = async () => {
    try {
      const res = await rf.getRequest('BurnRequest').getSignatureGen0(account);
      if (res && res?.signature) {
        setSignature(res?.signature);
      }
    } catch (e) {
      setSignature('');
      console.error(e);
    }
  };

  useEffectUnsafe(() => {
    getSignatureGen0().then();
  }, [account]);

  return (
    <div className="MintPage__mint__info">
      <div className="MintPage__mint__info__noti">
        {phaseStatus === EPhaseStatus.COMING_SOON
          ? 'MINTING IS NOT START YET'
          : 'MINTING NOW'}
      </div>

      <div className="MintPage__mint__info__header">
        <div className="MintPage__mint__info__header__phase">
          Phase 3: Gen0 - Legendary Stoned Homies
          <div className="MintPage__mint__info__header__status">
            <PhaseStatus status={phaseStatus} />
          </div>
        </div>
      </div>

      <div className="MintPage__mint__info__description">
        If you're chillin' in the top 300 $PACM holders, you're snagging a NFT
        Gen0 for that sweet FREE-MINT. Short on coins? Get some $PACM and roll
        back to us, homie!
      </div>

      <About />

      {dueDate && (
        <div className="MintPage__mint__info__countdown--coming-soon">
          <div className="MintPage__mint__info__countdown--coming-soon__text">
            {phaseStatus === EPhaseStatus.COMING_SOON
              ? `Mint Process will start in:`
              : 'Mint Process will end in:'}
          </div>
          <CountDownBox
            phaseStatus={phaseStatus}
            dueDate={moment(dueDate).format('YYYY/MM/DD HH:mm:ss')}
          />
        </div>
      )}

      <div className="MintPage__mint__info__mint-amount">
        <div className="MintPage__mint__info__connect-wallet">
          {!account ? (
            <>
              <ConnectWalletBtn onConnectWallet={onConnectWallet} />
              <div className="MintPage__mint__info__connect-wallet__warning">
                Please Connect your Wallet before minting
              </div>
            </>
          ) : !signature ? (
            <NotWhitelistedBtn />
          ) : (
            <MintBtn disabled={disableMint} onMint={onMint} />
          )}
        </div>
      </div>

      <ModalTransactionProcess
        isLoading={isLoadingConfirm}
        onClose={() => setIsShowModalTransaction(false)}
        open={isShowModalTransaction}
        txHashInfo={txHashInfo}
      />
    </div>
  );
};

export default Phase2;
