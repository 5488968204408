import BaseModal from './BaseModal';
import React, { FC, useMemo, useState } from 'react';
import '../styles/modals/Modal.scss';
import { IconClose } from '../assets/icons';
import { WrapButton } from '../components';
import { NumericFormat } from 'react-number-format';
import config from '../config';
import {
  useAddListingCallback,
  useApproveNFT,
} from '../hooksUniswap/useNFTMarketCallback';
import {
  convertDecToWei,
  formatNumber,
} from '../utils/format';
import {
  useNFTContract,
  useNFTMarketContract,
} from '../hooksUniswap/useContract';
import { useActiveWeb3React } from '../hooksUniswap';
import { useEffectUnsafe } from '../hooks/useEffectUnsafe';
import BigNumber from 'bignumber.js';

interface IModalListNFT {
  open: boolean;
  isNFTGen0: boolean;
  onClose: () => void;
  urlNFT: string;
  nftId: string;
  addressNFT: string;
  setIsLoadingConfirm: (value: boolean) => void;
  setIsShowModalTransaction: (value: boolean) => void;
  setTxHashInfo: (value: any) => void;
  fetchData: () => void;
}

const ONE_HUNDRED_YEARS = 3153600000;
const CURRENCY_ADDRESS = config.token_address;
const NFT_MARKET_CONTRACT_ADDRESS = config.contracts.nft_market_contract;

const ModalListNFT: FC<IModalListNFT> = ({
  open,
  onClose,
  urlNFT,
  nftId,
  addressNFT,
  setIsLoadingConfirm,
  setIsShowModalTransaction,
  setTxHashInfo,
  fetchData,
  isNFTGen0,
}) => {
  const [price, setPrice] = useState<any>('');
  const [isApproved, setIsApproved] = useState<boolean>(false);
  const [fee, setFee] = useState<string>('0');
  const { account } = useActiveWeb3React();

  const [addListingCallback] = useAddListingCallback(
    NFT_MARKET_CONTRACT_ADDRESS,
    addressNFT,
    nftId,
    convertDecToWei(price, 18),
    CURRENCY_ADDRESS,
    Math.floor(new Date().valueOf() / 1000),
    Math.floor(new Date().valueOf() / 1000) + ONE_HUNDRED_YEARS,
  );

  const nftMarketContract = useNFTMarketContract(NFT_MARKET_CONTRACT_ADDRESS);
  const nftContract = useNFTContract(addressNFT);

  const getTax = async () => {
    const convertMarketTax = (tax: any) => {
      return new BigNumber(tax)
        .div(new BigNumber(2).pow(64))
        .multipliedBy(100)
        .integerValue(BigNumber.ROUND_CEIL)
        .toString();
    };
    const defaultTax = await nftMarketContract?.defaultTax();
    setFee(convertMarketTax(defaultTax?.toString()));
  };

  useEffectUnsafe(() => {
    getTax().then();
  }, [addressNFT]);

  const [approveNFTCallback] = useApproveNFT(
    addressNFT,
    NFT_MARKET_CONTRACT_ADDRESS,
  );

  const fetchIsApproveForAll = async () => {
    if (!nftContract) return;
    try {
      const isApproved = await nftContract?.isApprovedForAll(
        account,
        NFT_MARKET_CONTRACT_ADDRESS,
      );
      setIsApproved(isApproved);
    } catch (e) {
      console.error(e);
    }
  };

  useEffectUnsafe(() => {
    fetchIsApproveForAll().then();
  }, [account]);

  const onListNFT = async () => {
    if (!price) return;

    setIsLoadingConfirm(true);
    setIsShowModalTransaction(true);

    const onListNFTHandle = () => {
      return addListingCallback()
        .then(async (hash) => {
          await new Promise((r) => setTimeout(r, 5 * config.block_time * 1000));
          await fetchData();
          setIsLoadingConfirm(false);
          setTxHashInfo({ hash });
          onClose();
        })
        .catch((error) => {
          setIsLoadingConfirm(false);
          setTxHashInfo((prev: any) => ({
            ...prev,
            errorMessage: error.message,
          }));
        });
    };

    return onListNFTHandle();
  };

  const onApproveNFT = async () => {
    if (!price) return;

    setIsLoadingConfirm(true);
    setIsShowModalTransaction(true);

    const onApproveNFTHandle = () => {
      return approveNFTCallback()
        .then(async (hash) => {
          await new Promise((r) => setTimeout(r, 5 * config.block_time * 1000));
          setIsLoadingConfirm(false);
          setTxHashInfo({ hash });
          setIsApproved(true);
        })
        .catch((error) => {
          setIsLoadingConfirm(false);
          setTxHashInfo((prev: any) => ({
            ...prev,
            errorMessage: error.message,
          }));
        });
    };

    return onApproveNFTHandle();
  };

  const isDisabled = useMemo(() => !price, [price]);

  const _renderButton = () => {
    if (!price) {
      return (
        <div style={{ opacity: isDisabled ? 0.5 : 1 }}>
          <WrapButton className="modal-buy-nft__btn-buy">List Now</WrapButton>
        </div>
      );
    }

    if (!isApproved) {
      return (
        <div onClick={onApproveNFT} style={{ opacity: isDisabled ? 0.5 : 1 }}>
          <WrapButton className="modal-buy-nft__btn-buy">Approve</WrapButton>
        </div>
      );
    }

    return (
      <div onClick={onListNFT} style={{ opacity: isDisabled ? 0.5 : 1 }}>
        <WrapButton className="modal-buy-nft__btn-buy">List Now</WrapButton>
      </div>
    );
  };

  const getLastPrice = () => {
    if (!price) return '--';
    if (!+fee) return price;

    const priceTotal = new BigNumber(
      new BigNumber(fee).dividedBy(100).multipliedBy(new BigNumber(price)),
    ).plus(new BigNumber(price));

    return formatNumber(priceTotal, 8);
  };

  return (
    <BaseModal
      open={open}
      isHideOverlay
      className="modal-buy-nft modal-list-nft"
    >
      <div>
        <div className="modal__icon-close" onClick={onClose}>
          <IconClose />
        </div>

        <div className="modal__content">
          <div className="modal-list-nft__detail">
            <img src={urlNFT} />

            <div className="modal-list-nft__form">
              <div className="modal-list-nft__form__content">
                <div className="modal-list-nft__info">
                  <div>ID</div>
                  <div>
                    P@MAN {isNFTGen0 ? 'GEN0' : 'GEN1'} #{nftId}
                  </div>
                </div>

                <div className="modal-list-nft__box-input">
                  <div className="modal-list-nft__box-input__label">
                    Price <span>*</span>
                  </div>
                  <div>
                    <NumericFormat
                      placeholder="0"
                      value={price ?? ''}
                      allowLeadingZeros
                      allowNegative={false}
                      thousandSeparator=","
                      decimalScale={8}
                      onValueChange={({ floatValue }) => {
                        return setPrice(floatValue);
                      }}
                    />
                    <div>$PACM</div>
                  </div>
                </div>

                <div className="modal-list-nft__bottom">
                  <div>
                    <div className="modal-list-nft__rotalty-fee">
                      <div>ROTALTY FEE</div>
                      <div>{Number(fee).toFixed(2)}%</div>
                    </div>
                    <div className="modal-list-nft__noti">
                      P@cman's marketplace fees.
                    </div>

                    <div className="modal-list-nft__total-earning">
                      <div>LAST PRICE</div>
                      <div>
                        <span>{getLastPrice()}</span>$PACM
                      </div>
                    </div>
                  </div>

                  {_renderButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default ModalListNFT;
