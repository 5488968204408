import React, { useCallback, useMemo, useState } from 'react';
import { ArrowLight, IconFilter } from '../../assets/icons';
import ItemNFT from './ItemNFT';
import GroupFilter from './GroupFilter';
import rf from '../../services/RequestFactory';
import { useEffectUnsafe } from '../../hooks/useEffectUnsafe';
import { getPriceNFT } from '../../hooksUniswap/useNFTMarketCallback';
import { useNFTMarketContract } from '../../hooksUniswap/useContract';
import config from '../../config';
import { debounce } from 'lodash';
import { filterParams } from '../../utils/helper';
import { AppPagination } from '../../components';
import { ModalFilter } from '../../modals';

const NFT_MARKET_CONTRACT_ADDRESS = config.contracts.nft_market_contract;

const SORT_TYPE = {
  ASC: 'asc',
  DESC: 'desc',
};

const MenuSortPrice = ({ params, setParams }: any) => {
  const [isShow, setIsShow] = useState<boolean>(false);

  return (
    <div className="marketplace__filter__sort">
      <div
        className="marketplace__filter__sort__btn"
        onClick={() => setIsShow(!isShow)}
      >
        <div>
          Price:{' '}
          <span>
            {params.sortByPrice === SORT_TYPE.ASC
              ? 'Low to High'
              : 'High to Low'}{' '}
          </span>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M11.7354 14.876L7.14278 9.90314C6.85621 9.59284 7.02992 8.99963 7.40736 8.99963L16.5926 8.99963C16.9701 8.99963 17.1438 9.59284 16.8572 9.90314L12.2646 14.876C12.1123 15.0408 11.8877 15.0408 11.7354 14.876Z"
            fill="white"
          />
        </svg>
      </div>

      {isShow && (
        <div className="marketplace__filter__sort__menu">
          <div
            onClick={() => {
              setParams({ ...params, sortByPrice: SORT_TYPE.ASC });
              setIsShow(false);
            }}
            className={`marketplace__filter__sort__menu-item ${
              params.sortByPrice === SORT_TYPE.ASC ? 'active' : ''
            }`}
          >
            Low to High
          </div>
          <div
            onClick={() => {
              setParams({ ...params, sortByPrice: SORT_TYPE.DESC });
              setIsShow(false);
            }}
            className={`marketplace__filter__sort__menu-item ${
              params.sortByPrice === SORT_TYPE.DESC ? 'active' : ''
            }`}
          >
            High to Low
          </div>
        </div>
      )}
    </div>
  );
};

const ListNFT = () => {
  const [params, setParams] = useState<any>({
    nftId: '',
    sortByPrice: 'asc',
    bg: '',
    head: '',
    hair: '',
    accessories: '',
    glasses: '',
    hat: '',
    mouth: '',
    page: 1,
    limit: 20,
  });
  const [isShowFilter, setIsShowFilter] = useState<boolean>(true);
  const [isShowModalFilter, setIsShowModalFilter] = useState<boolean>(false);
  const [listNFT, setListNFT] = useState<any[]>([]);
  const [priceNFTs, setPriceNFTs] = useState<any>({});
  const [totalItems, setTotalItems] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  const marketContract = useNFTMarketContract(NFT_MARKET_CONTRACT_ADDRESS);

  const fetchNftPrices = async (nfts: []) => {
    const nftPricesById: any = {};
    await Promise.all(
      nfts.map(async (item: any) => {
        nftPricesById[item?.nftId] = await getPriceNFT(
          marketContract,
          item?.nftAddress,
          item?.nftId,
        );
      }),
    );
    setPriceNFTs(nftPricesById);
  };

  const getListNFT = async (payload: any) => {
    try {
      const res = await rf
        .getRequest('NFTRequest')
        .getListNFT(filterParams(payload));
      if (res && res?.docs) {
        setListNFT(res?.docs);
        setTotalItems(res?.totalDocs);
        setTotalPages(res?.totalPages);
        await fetchNftPrices(res?.docs);
      }
    } catch (error) {
      setListNFT([]);
      setTotalItems(0);
      setTotalPages(0);
    }
  };

  const debounceFetchTableData = useCallback(debounce(getListNFT, 1000), [
    params,
  ]);

  useEffectUnsafe(() => {
    debounceFetchTableData(params);
    return () => {
      debounceFetchTableData.cancel();
    };
  }, [debounceFetchTableData]);

  useEffectUnsafe(() => {
    if (isShowModalFilter) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'inherit';
    }
  }, [isShowModalFilter]);

  const onChangePagination = (event: { selected: number }) => {
    setParams({ ...params, page: event.selected + 1 });
  };

  const paramPropertiesNFT = useMemo(() => {
    const filteredKeys = [
      'bg',
      'head',
      'hair',
      'accessories',
      'glasses',
      'hat',
      'mouth',
    ];

    const paramFilter = filteredKeys.reduce(
      (obj, key) => ({ ...obj, [key]: params[key] }),
      {},
    );

    return Object.entries(filterParams(paramFilter));
  }, [params]);

  return (
    <div className="marketplace__list-nft">
      <div
        className="marketplace__filter__filter-btn mobile"
        onClick={() => setIsShowModalFilter(true)}
      >
        <IconFilter />
        {!!paramPropertiesNFT.length && (
          <div className="marketplace__filter__total">
            {paramPropertiesNFT.length}
          </div>
        )}
      </div>

      <div className="marketplace__filter">
        <div
          className="marketplace__filter__filter-btn"
          onClick={() => setIsShowFilter(!isShowFilter)}
        >
          {isShowFilter ? <ArrowLight /> : <IconFilter />}
          <span>Filter</span>

          {!!paramPropertiesNFT.length && (
            <div className="marketplace__filter__total">
              {paramPropertiesNFT.length}
            </div>
          )}
        </div>
        <div className="marketplace__filter__search">
          <input
            value={params.nftId}
            placeholder="Search by ID..."
            onChange={(e) => setParams({ ...params, nftId: e.target.value })}
          />
        </div>
        <MenuSortPrice params={params} setParams={setParams} />
      </div>

      <div className="marketplace__content">
        <div
          className={`marketplace__content--left ${
            isShowFilter ? 'show' : 'hide'
          }`}
        >
          <GroupFilter params={params} setParams={setParams} />
        </div>

        <div className="marketplace__content--right">
          <div className="marketplace__total-result">
            <div className="marketplace__total">{totalItems} Total Results</div>
            {paramPropertiesNFT.map((item: any, index: number) => {
              return (
                <div className="marketplace__tag" key={index}>
                  {item[1]}
                  <div
                    onClick={() => {
                      params[item[0]] = '';
                      setParams({ ...params });
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.50533 3.64608C4.7006 3.45082 5.01718 3.45082 5.21244 3.64608L8.8589 7.29254L12.5054 3.64608C12.7006 3.45082 13.0172 3.45082 13.2125 3.64608C13.4077 3.84134 13.4077 4.15793 13.2125 4.35319L9.56601 7.99965L13.2124 11.6461C13.4077 11.8413 13.4077 12.1579 13.2124 12.3532C13.0172 12.5484 12.7006 12.5484 12.5053 12.3532L8.8589 8.70675L5.21244 12.3532C5.01718 12.5485 4.7006 12.5485 4.50533 12.3532C4.31007 12.1579 4.31007 11.8414 4.50533 11.6461L8.15179 7.99965L4.50533 4.35319C4.31007 4.15793 4.31007 3.84134 4.50533 3.64608Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
              );
            })}
          </div>

          <div
            className={`marketplace__list ${isShowFilter ? 'hide' : 'full'}`}
          >
            {listNFT.map((item, index) => {
              return (
                <ItemNFT
                  fetchData={() => setParams({ ...params })}
                  key={index}
                  nft={item}
                  priceNFTs={priceNFTs}
                />
              );
            })}
          </div>

          {totalPages > 1 && (
            <AppPagination
              pageCount={totalPages}
              forcePage={params?.page - 1}
              onPageChange={onChangePagination}
            />
          )}
        </div>
      </div>

      <ModalFilter
        params={params}
        setParams={setParams}
        open={isShowModalFilter}
        onClose={() => setIsShowModalFilter(false)}
      />
    </div>
  );
};

export default ListNFT;
