import React from 'react';
import { FC } from 'react';
import '../../styles/pages/HomePage.scss';
import { CountUp } from 'use-count-up';
import { formatCommaNumber } from '../../utils/format';

const TOTAL_SUPPLY = 1999420000000;

const PartTokenomics: FC = () => {
  return (
    <div className="tokenomics">
      <div className="tokenomics__bg-top"></div>
      <div className="tokenomics__title">
        <img src="/images/home-page/tokenomics.svg" />
        <div className="tokenomics__coin1">
          <img src="/images/home-page/coin1.png" />
        </div>

        <div className="tokenomics__coin5">
          <img src="/images/home-page/coin5.png" />
        </div>
      </div>
      <div className="tokenomics__divider">
        <div className="tokenomics__divider-line"></div>
        <div className="tokenomics__divider-text">total supply</div>
        <div className="tokenomics__divider-line"></div>
      </div>

      <div className="tokenomics__content">
        <div className="tokenomics__map-top">
          <img src="/images/home-page/map-top.png" />
        </div>
        <div className="tokenomics__number">
          <CountUp
            isCounting
            end={TOTAL_SUPPLY}
            duration={6}
            formatter={(number) => formatCommaNumber(number.toFixed(0))}
          />
          <div className="tokenomics__coin3">
            <img src="/images/home-page/coin3.png" />
          </div>

          <div className="tokenomics__coin2">
            <img src="/images/home-page/coin2.png" />
          </div>

          <div className="tokenomics__coin4">
            <img src="/images/home-page/coin4.png" />
          </div>
        </div>

        <div className="tokenomics__infos">
          <div className="tokenomics__infos-item border-style-game">
            <div>{`~2B+ = TVL on Blast`}</div>
          </div>
          <div className="tokenomics__infos-item border-style-game">
            <div>1999 = P@cman Born</div>
          </div>
          <div className="tokenomics__infos-item border-style-game">
            <div>Tax 0%</div>
            <div className="tax">TAX</div>
          </div>
        </div>

        <div className="tokenomics__desc">
          PLUS ++++++, Zero Loss. All Gain! <br />
          Stealth Launch, LP Burnt, Contract Renounced? You know we've got that
          classic!
          <br /> Lemme show you dah txid for burning LP & renouncing contract
          ownership baby!
        </div>

        <div className="tokenomics__group-btn">
          <a
            href="https://blastscan.io/tx/0x071a564b010700fb4392ba9db8133d35028065f0fd0316cf9151802861bb649d"
            target="_blank"
          >
            <div className="border-style-game tokenomics__btn-burn">
              <div className="tokenomics__btn-burn--content">
                <div>Burn LP TxID</div>
                <div>
                  🔥0x071a564b010700fb4392ba9db8133d35028065f0fd0316cf9151802861bb649d
                </div>
              </div>
            </div>
          </a>

          <a
            href="https://blastscan.io/tx/0x0212d0e90e346e88abcda241130f0de9e007a55c7fa131500955e5d26ed46cb8"
            target="_blank"
          >
            <div className="border-style-game tokenomics__btn-burn">
              <div className="tokenomics__btn-burn--content">
                <div>Renounce Ownership TxID</div>
                <div>
                  🔥0x0212d0e90e346e88abcda241130f0de9e007a55c7fa131500955e5d26ed46cb8
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default PartTokenomics;
