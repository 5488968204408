import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setAuthorizationToRequest } from '../utils/authenticate';
import Storage from '../utils/storage';
import rf from '../services/RequestFactory';

export type UserState = {
  accessToken: string;
  refreshToken: string;
  balance: string;
  isOpenModalConnectWallet: boolean;
};

const initialState: UserState = {
  accessToken: Storage.getAccessToken() || '',
  refreshToken: Storage.getRefreshToken() || '',
  balance: '',
  isOpenModalConnectWallet: false,
};

export const getBalancesUser = createAsyncThunk(
  'user/getWallets',
  async (_params, thunkApi) => {
    const res = await rf.getRequest('AuthRequest').getProfile();
    thunkApi.dispatch(setBalance(res.balance));
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserAuth: (state, action) => {
      const { accessToken, refreshToken } = action.payload;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;

      if (accessToken && refreshToken) {
        Storage.setAccessToken(accessToken);
        Storage.setRefreshToken(refreshToken);
        setAuthorizationToRequest(accessToken);
      }
    },
    setBalance: (state, action) => {
      state.balance = action.payload;
    },
    setIsOpenModalConnectWallet: (state, action) => {
      state.isOpenModalConnectWallet = action.payload;
    },
    clearUser: () => {
      setAuthorizationToRequest(null);
      Storage.logout();
      return {
        accessToken: '',
        refreshToken: '',
        balance: '',
        isOpenModalConnectWallet: false,
      };
    },
  },
});

export const {
  setUserAuth,
  clearUser,
  setBalance,
  setIsOpenModalConnectWallet,
} = userSlice.actions;

export default userSlice.reducer;
