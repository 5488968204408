import { createSlice } from '@reduxjs/toolkit';
import { getVersionUpgrade, VersionUpgrade } from '@uniswap/token-lists';
import { TokenList } from '@uniswap/token-lists/dist/types';
import {
  DEFAULT_LIST_OF_LISTS,
  DEFAULT_TOKEN_LIST_URL,
} from '../constants/lists';

export interface ListsState {
  readonly byUrl: {
    readonly [url: string]: {
      readonly current: TokenList | null;
      readonly pendingUpdate: TokenList | null;
      readonly loadingRequestId: string | null;
      readonly error: string | null;
    };
  };
  // this contains the default list of lists from the last time the updateVersion was called, i.e. the app was reloaded
  readonly lastInitializedDefaultListOfLists?: string[];
  readonly selectedListUrl: string | undefined;
}

const NEW_LIST_STATE: ListsState['byUrl'][string] = {
  error: null,
  current: null,
  loadingRequestId: null,
  pendingUpdate: null,
};

type Mutable<T> = {
  -readonly [P in keyof T]: T[P] extends ReadonlyArray<infer U> ? U[] : T[P];
};

const initialState: ListsState = {
  lastInitializedDefaultListOfLists: DEFAULT_LIST_OF_LISTS,
  byUrl: {
    ...DEFAULT_LIST_OF_LISTS.reduce<Mutable<ListsState['byUrl']>>(
      (memo, listUrl) => {
        memo[listUrl] = NEW_LIST_STATE;
        return memo;
      },
      {},
    ),
    [DEFAULT_TOKEN_LIST_URL]: {
      error: null,
      current: null,
      loadingRequestId: null,
      pendingUpdate: null,
    },
  },
  selectedListUrl: undefined,
};

const listsSlice = createSlice({
  name: 'lists',
  initialState,
  reducers: {
    fetchTokenListPending: (state, { payload: { requestId, url } }) => {
      state.byUrl[url] = {
        ...state.byUrl[url],
        loadingRequestId: requestId,
        error: null,
      };
    },
    fetchTokenListFulfilled: (
      state,
      { payload: { requestId, tokenList, url } },
    ) => {
      const current = state.byUrl[url]?.current;
      const loadingRequestId = state.byUrl[url]?.loadingRequestId;

      // no-op if update does nothing
      if (current) {
        const upgradeType = getVersionUpgrade(
          current.version,
          tokenList.version,
        );
        if (upgradeType === VersionUpgrade.NONE) return;
        if (loadingRequestId === null || loadingRequestId === requestId) {
          state.byUrl[url] = {
            ...state.byUrl[url],
            loadingRequestId: null,
            error: null,
            current: current,
            pendingUpdate: tokenList,
          };
        }
      } else {
        state.byUrl[url] = {
          ...state.byUrl[url],
          loadingRequestId: null,
          error: null,
          current: tokenList,
          pendingUpdate: null,
        };
      }
    },
    fetchTokenListRejected: (
      state,
      { payload: { url, requestId, errorMessage } },
    ) => {
      if (state.byUrl[url]?.loadingRequestId !== requestId) {
        // no-op since it's not the latest request
        return;
      }

      state.byUrl[url] = {
        ...state.byUrl[url],
        loadingRequestId: null,
        error: errorMessage,
        current: null,
        pendingUpdate: null,
      };
    },
    selectList: (state, { payload: url }) => {
      state.selectedListUrl = url;
      // automatically adds list
      if (!state.byUrl[url]) {
        state.byUrl[url] = NEW_LIST_STATE;
      }
    },
    addList: (state, { payload: url }) => {
      if (!state.byUrl[url]) {
        state.byUrl[url] = NEW_LIST_STATE;
      }
    },
    removeList: (state, { payload: url }) => {
      if (state.byUrl[url]) {
        delete state.byUrl[url];
      }
      if (state.selectedListUrl === url) {
        state.selectedListUrl = Object.keys(state.byUrl)[0];
      }
    },
    acceptListUpdate: (state, { payload: url }) => {
      if (!state.byUrl[url]?.pendingUpdate) {
        throw new Error('accept list update called without pending update');
      }
      state.byUrl[url] = {
        ...state.byUrl[url],
        pendingUpdate: null,
        current: state.byUrl[url].pendingUpdate,
      };
    },
    updateVersion: (state) => {
      // state loaded from localStorage, but new lists have never been initialized
      if (!state.lastInitializedDefaultListOfLists) {
        state.byUrl = initialState.byUrl;
        state.selectedListUrl = undefined;
      } else if (state.lastInitializedDefaultListOfLists) {
        const lastInitializedSet =
          state.lastInitializedDefaultListOfLists.reduce<Set<string>>(
            (s, l) => s.add(l),
            new Set(),
          );
        const newListOfListsSet = DEFAULT_LIST_OF_LISTS.reduce<Set<string>>(
          (s, l) => s.add(l),
          new Set(),
        );

        DEFAULT_LIST_OF_LISTS.forEach((listUrl) => {
          if (!lastInitializedSet.has(listUrl)) {
            state.byUrl[listUrl] = NEW_LIST_STATE;
          }
        });

        state.lastInitializedDefaultListOfLists.forEach((listUrl) => {
          if (!newListOfListsSet.has(listUrl)) {
            delete state.byUrl[listUrl];
          }
        });
      }

      state.lastInitializedDefaultListOfLists = DEFAULT_LIST_OF_LISTS;
    },
  },
});

export const {
  selectList,
  updateVersion,
  acceptListUpdate,
  addList,
  removeList,
  fetchTokenListFulfilled,
  fetchTokenListPending,
  fetchTokenListRejected,
} = listsSlice.actions;

export default listsSlice.reducer;
