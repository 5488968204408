// the Uniswap Default token list lives here
export const DEFAULT_TOKEN_LIST_URL = '';

export const DEFAULT_LIST_OF_LISTS: string[] = [DEFAULT_TOKEN_LIST_URL];

export const DEFAULT_LIST = {
  name: 'Labs Default',
  timestamp: '2023-12-13T18:25:25.830Z',
  version: {
    major: 11,
    minor: 11,
    patch: 0,
  },
  tags: {},
  logoURI:
    'https://www.google.com/url?sa=i&url=https%3A%2F%2Fcoinmarketcap.com%2Fcurrencies%2Fpepe%2F&psig=AOvVaw2gltJvcuzwIlciFObQLnKd&ust=1703663757578000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCOji3JvQrIMDFQAAAAAdAAAAABAD',
  keywords: ['default'],
  tokens: [
    {
      name: 'PACM',
      address: '0x0B4d0ee29857c3961b380d4ec138EA5814E346b9',
      symbol: 'PACM',
      decimals: 18,
      chainId: 81457,
      logoURI:
        'https://olive-hollow-coral-921.mypinata.cloud/ipfs/QmPNSPYuPFAE887QbUhUH1hyqDtX3Ui86322eauJ6rJKgZ',
    },
    {
      name: 'Wrapped Ether',
      address: '0x4300000000000000000000000000000000000004',
      symbol: 'WETH',
      decimals: 18,
      chainId: 81457,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
    },
  ],
};
