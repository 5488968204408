import React, { useEffect, useState } from 'react';
import '../../../styles/pages/MintPage.scss';
import { getDiffDuration } from '../../../utils/day';
import { EPhaseStatus } from '../Mint';

const CountDownBox: React.FC<{
  dueDate: Date | string;
  phaseStatus: EPhaseStatus;
}> = ({ dueDate }) => {
  const [duration, setDuration] = useState(getDiffDuration(dueDate));

  useEffect(() => {
    const interval = setInterval(() => {
      const duration = getDiffDuration(dueDate);
      if (duration.asSeconds() <= 0) {
        clearInterval(interval);
      } else {
        setDuration(duration);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [dueDate]);

  const getTwoDigits = (value: number) => {
    return ('0' + (value < 0 ? '0' : value)).slice(-2);
  };

  return (
    <>
      <div className="MintPage__mint__info__countdown__box">
        <CountDownBoxItem value={getTwoDigits(duration.days())} />
        {':'}
        <CountDownBoxItem value={getTwoDigits(duration.hours())} />
        {':'}
        <CountDownBoxItem value={getTwoDigits(duration.minutes())} />
        {':'}
        <CountDownBoxItem value={getTwoDigits(duration.seconds())} />
      </div>
    </>
  );
};

const CountDownBoxItem: React.FC<{ value: string }> = ({ value }) => {
  return <div className="MintPage__mint__info__countdown__item">{value}</div>;
};

export default CountDownBox;
