import React, { useState } from 'react';
import '../../../styles/pages/MintPage.scss';
import { EPhaseStatus, SoldOutBtn } from '../Mint';
import PhaseStatus from './PhaseStatus';

const Completed: React.FC = () => {
  const [isShowFull, setIsShowFull] = useState<boolean>(false);
  return (
    <div className="MintPage__mint__info">
      <div className="MintPage__mint__info__noti">{'MINTING IS OVER'}</div>

      <div className="MintPage__mint__info__header">
        <div className="MintPage__mint__info__header__phase">
          P@CMAN COLLECTION
        </div>

        <PhaseStatus status={EPhaseStatus.COMPLETED} />
      </div>

      {/*<div className="MintPage__mint__info__description">*/}
      {/*  For user deposit at least 0.01 ETH on P@cman Blast Meme. Your NFT will*/}
      {/*  be revealed on 22/2*/}
      {/*</div>*/}

      <div className="MintPage__mint__info__about">
        <div>ABOUT P@CMAN</div>
        <div
          className={`MintPage__mint__info__about__desc ${
            isShowFull ? 'full' : 'hide'
          }`}
        >
          The P@cman NFT is like, a high five to all the peeps who vibe high and
          do their own thing, while they cruise through life's wild ride with a
          big ol' grin 🚬. It is your rad badge of honor, celebrating how you
          boldly go where no one's gone before, with a laid-back, fearless
          attitude that's just cruisin' through life, ya know?
        </div>
        {!isShowFull && (
          <div
            className="MintPage__mint__info__see-more"
            onClick={() => setIsShowFull(true)}
          >
            See More{' '}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M7.82364 9.91757L4.76188 6.60233C4.57083 6.39547 4.68663 6 4.93826 6L11.0618 6C11.3134 6 11.4292 6.39547 11.2382 6.60233L8.1764 9.91757C8.07491 10.0275 7.92513 10.0275 7.82364 9.91757Z"
                fill="#7070FF"
              />
            </svg>
          </div>
        )}
      </div>

      <div className="MintPage__mint__info__mint-amount">
        <div className="MintPage__mint__info__connect-wallet">
          <SoldOutBtn />
        </div>
      </div>
    </div>
  );
};

export default Completed;
