import BaseModal from './BaseModal';
import React, { FC, useMemo } from 'react';
import '../styles/modals/Modal.scss';
import { IconClose } from '../assets/icons';
import { WrapButton } from '../components';
import {
  useBuyNFTCallback,
  useCancelListingCallback,
} from '../hooksUniswap/useNFTMarketCallback';
import config from '../config';
import { useActiveWeb3React } from '../hooksUniswap';
import {
  ApprovalState,
  useApproveCallback,
} from '../hooksUniswap/useApproveCallback';
import { setIsOpenModalConnectWallet } from '../store/user';
import { useDispatch } from 'react-redux';
import { Token, TokenAmount } from '@blast/v2-sdk';
import { TOKEN_DECIMALS } from '../pages/MintPage/Mint';
import { useTokenBalance } from '../store/hooks/wallet';

interface IModalBuyNFT {
  open: boolean;
  nft: any;
  onClose: () => void;
  setIsLoadingConfirm: (value: boolean) => void;
  setIsShowModalTransaction: (value: boolean) => void;
  setTxHashInfo: (value: any) => void;
  fetchData: () => void;
  priceCurrent: any;
  isSold: boolean;
  isOwnerNFT: boolean;
  isNFTGen0: boolean;
  priceNFT: any;
}

const NFT_MARKET_CONTRACT_ADDRESS = config.contracts.nft_market_contract;

const ModalBuyNFT: FC<IModalBuyNFT> = ({
  open,
  onClose,
  setTxHashInfo,
  nft,
  setIsLoadingConfirm,
  setIsShowModalTransaction,
  priceCurrent,
  isSold,
  isOwnerNFT,
  priceNFT,
  isNFTGen0,
  fetchData,
}) => {
  const { account, chainId } = useActiveWeb3React();
  const dispatch = useDispatch();

  const NFT_ADDRESS = useMemo(() => nft?.nftAddress || '', [nft]);
  const CURRENCY_CONTRACT_ADDRESS = useMemo(() => nft?.currency || '', [nft]);

  const [buyNFTCallback] = useBuyNFTCallback(
    NFT_MARKET_CONTRACT_ADDRESS,
    NFT_ADDRESS,
    nft?.nftId,
    priceNFT,
    CURRENCY_CONTRACT_ADDRESS,
  );

  const [cancelListingCallback] = useCancelListingCallback(
    NFT_MARKET_CONTRACT_ADDRESS,
    NFT_ADDRESS,
    nft?.nftId,
  );

  const onConnectWallet = () => {
    dispatch(setIsOpenModalConnectWallet(true));
  };

  const token = new Token(
    chainId as number,
    CURRENCY_CONTRACT_ADDRESS,
    TOKEN_DECIMALS,
  );

  const { isLoading } = useTokenBalance(account ?? undefined, token);

  const amountToApprove = useMemo(() => {
    return new TokenAmount(token, priceNFT);
  }, []);

  const [approval, approveCallback] = useApproveCallback(
    amountToApprove,
    NFT_MARKET_CONTRACT_ADDRESS,
  );

  const onApprove = async () => {
    if (approval !== ApprovalState.APPROVED) {
      setIsLoadingConfirm(true);
      setIsShowModalTransaction(true);
      try {
        const hash = await approveCallback();
        await new Promise((r) => setTimeout(r, config.block_time * 1000));
        setIsLoadingConfirm(false);
        setTxHashInfo({ hash });
      } catch (error: any) {
        setIsLoadingConfirm(false);
        setTxHashInfo((prev: any) => ({
          ...prev,
          errorMessage: error.message,
        }));
      }
    }
  };

  const onBuyNFT = async () => {
    onClose();
    setIsLoadingConfirm(true);
    setIsShowModalTransaction(true);

    const onBuyNFTHandle = () => {
      return buyNFTCallback()
        .then(async (hash) => {
          await new Promise((r) => setTimeout(r, 5 * config.block_time * 1000));
          await fetchData();
          setIsLoadingConfirm(false);
          setTxHashInfo({ hash });
        })
        .catch((error) => {
          setIsLoadingConfirm(false);
          setTxHashInfo((prev: any) => ({
            ...prev,
            errorMessage: error.message,
          }));
        });
    };

    return onBuyNFTHandle();
  };

  const onDelistNFT = async () => {
    onClose();
    setIsLoadingConfirm(true);
    setIsShowModalTransaction(true);

    const onDelistNFTHandle = () => {
      return cancelListingCallback()
        .then(async (hash) => {
          await new Promise((r) => setTimeout(r, 5 * config.block_time * 1000));
          await fetchData();
          setIsLoadingConfirm(false);
          setTxHashInfo({ hash });
        })
        .catch((error) => {
          setIsLoadingConfirm(false);
          setTxHashInfo((prev: any) => ({
            ...prev,
            errorMessage: error.message,
          }));
        });
    };

    return onDelistNFTHandle();
  };

  const _renderButton = () => {
    if (isSold) {
      return (
        <div style={{ opacity: 0.5, cursor: 'not-allowed' }}>
          <WrapButton className="modal-buy-nft__btn-buy">Sold Out</WrapButton>
        </div>
      );
    }

    if (isLoading) {
      return (
        <div>
          <WrapButton className="modal-buy-nft__btn-buy">Loading...</WrapButton>
        </div>
      );
    }

    if (!account) {
      return (
        <div onClick={onConnectWallet}>
          <WrapButton className="modal-buy-nft__btn-buy">
            Connect Wallet
          </WrapButton>
        </div>
      );
    }

    if (isOwnerNFT) {
      return (
        <div onClick={onDelistNFT}>
          <WrapButton className="modal-buy-nft__btn-buy">Delist NFT</WrapButton>
        </div>
      );
    }

    if (approval !== ApprovalState.APPROVED) {
      return (
        <div
          style={{
            opacity: approval === ApprovalState.PENDING ? 0.5 : 1,
            cursor:
              approval === ApprovalState.PENDING ? 'not-allowed' : 'pointer',
          }}
          onClick={() => {
            if (approval === ApprovalState.PENDING) {
              return;
            }

            onApprove().then();
          }}
        >
          <WrapButton className="modal-buy-nft__btn-buy">
            {approval === ApprovalState.PENDING
              ? 'Approving...'
              : 'Approve Token'}
          </WrapButton>
        </div>
      );
    }

    return (
      <div onClick={onBuyNFT}>
        <WrapButton className="modal-buy-nft__btn-buy">Buy Now</WrapButton>
      </div>
    );
  };

  return (
    <BaseModal open={open} isHideOverlay className="modal-buy-nft">
      <div>
        <div className="modal__icon-close" onClick={onClose}>
          <IconClose />
        </div>

        <div className="modal__content">
          <div className="modal-buy-nft__detail">
            <img src={nft?.metadata?.image} />

            <div className="modal-buy-nft__trail">
              <div className="modal-buy-nft__trail__title">TRAIL</div>
              <div>
                <div className="modal-buy-nft__trail__item">
                  <div>
                    <span> Accessory</span>
                  </div>
                  <div>{nft?.metadata?.accessories}</div>
                </div>
                <div className="modal-buy-nft__trail__item">
                  <div>
                    <span>Background</span>
                  </div>
                  <div>{nft?.metadata?.bg}</div>
                </div>
                <div className="modal-buy-nft__trail__item">
                  <div>
                    <span>Glasses</span>
                  </div>
                  <div>{nft?.metadata?.glasses}</div>
                </div>
                <div className="modal-buy-nft__trail__item">
                  <div>
                    <span>Hair</span>
                  </div>
                  <div>{nft?.metadata?.hair}</div>
                </div>
                <div className="modal-buy-nft__trail__item">
                  <div>
                    <span>Hat</span>
                  </div>
                  <div>{nft?.metadata?.hat}</div>
                </div>
                <div className="modal-buy-nft__trail__item">
                  <div>
                    <span>Head</span>
                  </div>
                  <div>{nft?.metadata?.head}</div>
                </div>
                <div className="modal-buy-nft__trail__item">
                  <div>
                    <span>Mouth</span>
                  </div>
                  <div>{nft?.metadata?.mouth}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-buy-nft__info">
            <div className="modal-buy-nft__name">
              P@MAN {isNFTGen0 ? 'GEN0' : 'GEN1'} #{nft?.nftId}
            </div>
            <div className="modal-buy-nft__price">
              <div>Listing Price</div>
              {priceCurrent} PACM
            </div>
            {_renderButton()}
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default ModalBuyNFT;
