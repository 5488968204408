import BaseRequest from './BaseRequest';
import config from '../config';

export default class QuestRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.baseUrlApi;
  }

  loginTwitter() {
    const url = `/auth/twitter`;
    return this.get(url);
  }

  getUserProfile() {
    const url = `/user/profile`;
    return this.get(url);
  }

  getListQuest() {
    const url = `/quest`;
    return this.get(url);
  }

  markCompleted(questId: string) {
    const url = `/quest/${questId}/mark-completed`;
    return this.put(url);
  }

  claim(questId: string) {
    const url = `/quest/${questId}/claim`;
    return this.put(url);
  }

  addReferralCode(referralCode?: string) {
    const url = `/user/referred`;
    return this.put(url, { referralCode });
  }

  linkWallet(params: any) {
    const url = `/user/wallet`;
    return this.put(url, params);
  }
}
