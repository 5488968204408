import React, { useState, useEffect, useMemo } from 'react';
import '../../../styles/pages/MintPage.scss';
import rf from '../../../services/RequestFactory';
import { useEffectUnsafe } from '../../../hooks/useEffectUnsafe';
import { formatNumber, formatShortAddress } from '../../../utils/format';
import { useActiveWeb3React } from '../../../hooksUniswap';
import { AppPagination } from '../../../components';

interface IBurner {
  rank: number;
  burner: string;
  totalBurnAmount: string;
}

const LeaderBoard = ({ params }: any) => {
  const { account } = useActiveWeb3React();
  const [data, setData] = useState<IBurner[]>([]);
  const [page, setPage] = useState<number>(1);
  const [burner, setBurner] = useState<any>({});
  const [totalItems, setTotalItems] = useState<number>(0);

  const totalPages = useMemo(() => Math.ceil(totalItems / 10), [totalItems]);

  const getLeaderBoard = async () => {
    try {
      const res = await rf
        .getRequest('BurnRequest')
        .getLeaderBoard({ limit: 10, page });
      if (res) {
        setData(res?.leaderboard);
        setTotalItems(res?.totalItems);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getBurner = async () => {
    try {
      const res = await rf.getRequest('BurnRequest').getBurner(account);
      if (res) {
        setBurner(res);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffectUnsafe(() => {
    getBurner().then();
  }, [account, params]);

  useEffect(() => {
    const intervalBurner = setInterval(() => {
      getBurner().then();
    }, 5 * 60000);
    return () => clearInterval(intervalBurner);
  }, [account]);

  useEffect(() => {
    const intervalLeaderBoard = setInterval(() => {
      getLeaderBoard().then();
    }, 5 * 60000);
    return () => clearInterval(intervalLeaderBoard);
  }, [page]);

  useEffectUnsafe(() => {
    getLeaderBoard().then();
  }, [page, params]);

  const onChangePagination = (event: { selected: number }) => {
    setPage(event.selected + 1);
  };

  return (
    <div className="MintPage__leader-board">
      <div className="MintPage__leader-board__title">💠 LEADERBOARD</div>
      <div id="scrollableDiv" className="MintPage__leader-board__list">
        {!!data.length &&
          data.map((item: IBurner, index: number) => {
            return (
              <div key={index} className="MintPage__leader-board__item">
                <div>
                  <div>{item.rank}</div>
                  <div>{formatShortAddress(item.burner, 20, 10)}</div>
                </div>
                <div>{formatNumber(item.totalBurnAmount)}</div>
              </div>
            );
          })}
      </div>
      {account && (
        <div className="MintPage__leader-board__account">
          <div>
            <div>{burner?.rank}</div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              Your account
            </div>
          </div>
          <div>{formatNumber(burner?.totalBurnAmount || '')}</div>
        </div>
      )}

      <div className="MintPage__leader-board__pagination">
        <AppPagination
          pageCount={totalPages}
          forcePage={page - 1}
          onPageChange={onChangePagination}
        />
      </div>
    </div>
  );
};

export default LeaderBoard;
