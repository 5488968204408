import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router';
import {
  HashRouter,
  Route,
  RouteComponentProps,
  BrowserRouter as Router,
  Switch,
  withRouter,
} from 'react-router-dom';
import GoogleAnalyticsReporter from './components/GoogleAnalyticsReporter';
import ArcadeCenterPage from './pages/ArcadeCenterPage';
import FactoryPage from './pages/FactoryPage';
import HomePage from './pages/HomePage';
import BurnMintNFTPage from './pages/MintPage';
import MyNftPage from './pages/MyNftPage';
import Marketplace from './pages/Marketplace';

/**
 * Main App routes.
 */

const Routes: FC<RouteComponentProps> = () => {
  const { pathname } = useLocation();
  // scroll to top when route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Switch>
        {/*<Route path={'/arcade-center'} component={ArcadeCenterPage} />*/}
        <Route path={'/marketplace'} component={Marketplace} />
        {/*<Route path={'/factory'} component={FactoryPage} />*/}
        <Route path={'/mint'} component={BurnMintNFTPage} />
        <Route path={'/my-nft'} component={MyNftPage} />
        {/*<Route path={'/arcade-center'} component={ArcadeCenterPage} />*/}
        {/*<Route path={'/factory'} component={FactoryPage} />*/}
        <Route path={'/'} component={HomePage} />
      </Switch>
    </>
  );
};

const RoutesHistory = withRouter(Routes);

const routing = function createRouting() {
  return (
    <>
      <HashRouter>
        <Route component={GoogleAnalyticsReporter} />
        <Router>
          <RoutesHistory />
        </Router>
      </HashRouter>
    </>
  );
};
/**
 * Wrap the app routes into router
 *
 * PROPS
 * =============================================================================
 * @returns {React.Node}
 */
export default routing;
