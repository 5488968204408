import { ChainId } from '@blast/v2-sdk';
import React, { FC } from 'react';
import { IconClose, IconWarning } from '../assets/icons';
import { useActiveWeb3React } from '../hooksUniswap';
import '../styles/modals/Modal.scss';
import { formatShortAddress } from '../utils/format';
import { getEtherscanLink } from '../utils/swap';
import BaseModal from './BaseModal';
import { Oval } from 'react-loader-spinner';

interface IModalTransactionSwap {
  open: boolean;
  isLoading: boolean;
  txHashInfo?: { hash: string; errorMessage?: string };
  onClose: () => void;
  pendingText?: string;
}

const ModalTransactionSwap: FC<IModalTransactionSwap> = ({
  open,
  onClose,
  isLoading,
  txHashInfo,
  pendingText,
}) => {
  const { chainId } = useActiveWeb3React();

  const _renderContent = () => {
    if (isLoading) {
      return (
        <div className="modal__content">
          <div className="modal__content-title">Waiting For Transaction</div>
          <div className="modal__content-description">
            {pendingText ? pendingText : ''}
          </div>
          <div>
            <Oval
              visible={true}
              secondaryColor="#404833"
              height="56"
              width="56"
              color="#FCFC03"
              ariaLabel="oval-loading"
            />
          </div>
          <div className="modal__content-description">
            Confirm this transaction in your wallet
          </div>
        </div>
      );
    }

    if (!!txHashInfo?.errorMessage) {
      return (
        <div className="modal__content">
          <div className="modal__content-title">Error</div>
          <div>
            <IconWarning />
          </div>
          <div className="modal__content-description">
            <div className="modal__content-description--error">
              {txHashInfo?.errorMessage}
            </div>
          </div>

          <div className="modal__list-btn">
            <div className="modal__btn-outline" onClick={onClose}>
              Dismiss
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="modal__content">
        <div className="modal__content-title">Transaction Submitted</div>

        <div className="modal__content-description">
          View on Blastscan:{' '}
          <a
            className={'link'}
            href={getEtherscanLink(
              chainId || ChainId.BLAST_SEPOLIA,
              txHashInfo?.hash || '',
              'transaction',
            )}
            target={'_blank'}
          >
            {formatShortAddress(txHashInfo?.hash || '')}
          </a>
        </div>

        <div className="modal__list-btn" onClick={onClose}>
          <div className="modal__btn-outline">Close</div>
        </div>
      </div>
    );
  };

  return (
    <BaseModal
      zIndex={999}
      open={open}
      isHideOverlay
      className="modal-confirm-swap"
    >
      <div>
        <div className="modal__icon-close" onClick={onClose}>
          <IconClose />
        </div>

        {_renderContent()}
      </div>
    </BaseModal>
  );
};

export default ModalTransactionSwap;
