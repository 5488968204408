import React from 'react';

const ButtonSwap = ({ onClick, disabled, children }: any) => {
  if (disabled) {
    return <div className="swap__btn--disabled">{children}</div>;
  }

  return (
    <div className="swap__btn-swap" onClick={onClick}>
      {children}
    </div>
  );
};

export default ButtonSwap;
