import React, { FC, useMemo, useState } from 'react';
import '../styles/components/NftCard.scss';
import { IconEye } from '../assets/icons';
import { ModalListNFT, ModalTransactionProcess } from '../modals';
import config from '../config';
import { useEffectUnsafe } from '../hooks/useEffectUnsafe';

const NFT_GEN0_CONTRACT_ADDRESS = config.contracts.gen0_nft_contract;

const NftCard: FC<any> = ({
  src,
  nftId,
  address,
  width,
  fetchData,
  ...props
}) => {
  const [isShowModalListNFT, setIsShowModalListNFT] = useState<boolean>(false);
  const [isShowModalTransaction, setIsShowModalTransaction] =
    useState<boolean>(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState<boolean>(false);
  const [txHashInfo, setTxHashInfo] = useState<{
    hash: string;
    errorMessage?: string;
  }>();

  useEffectUnsafe(() => {
    if (isShowModalListNFT || isShowModalTransaction) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'inherit';
    }
  }, [isShowModalListNFT, isShowModalTransaction]);

  const isNFTGen0 = useMemo(
    () => address === NFT_GEN0_CONTRACT_ADDRESS,
    [address],
  );

  const url = useMemo(() => `${src}${nftId}.png`, [src, nftId]);

  return (
    <div className="NftCard" {...props}>
      <div className="NftCard__image">
        <img src={url} style={{ width: width ? width : '138px' }} />
        <div
          className="NftCard__btn"
          onClick={() => setIsShowModalListNFT(true)}
        >
          <div>List For Sale</div>
          <div>
            <IconEye />
          </div>
        </div>
      </div>

      <div className="NftCard__id">
        P@MAN {isNFTGen0 ? 'GEN0' : 'GEN1'} #{nftId}
      </div>

      {isShowModalListNFT && (
        <ModalListNFT
          isNFTGen0={isNFTGen0}
          setTxHashInfo={setTxHashInfo}
          setIsLoadingConfirm={setIsLoadingConfirm}
          setIsShowModalTransaction={setIsShowModalTransaction}
          nftId={nftId}
          urlNFT={url}
          addressNFT={address}
          open={isShowModalListNFT}
          onClose={() => setIsShowModalListNFT(false)}
          fetchData={fetchData}
        />
      )}

      {isShowModalTransaction && (
        <ModalTransactionProcess
          isLoading={isLoadingConfirm}
          onClose={() => {
            setIsShowModalTransaction(false);
            setTxHashInfo({
              hash: '',
              errorMessage: '',
            });
          }}
          open={isShowModalTransaction}
          txHashInfo={txHashInfo}
        />
      )}
    </div>
  );
};

export default NftCard;
