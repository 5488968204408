import React, { useRef } from 'react';
import { FC } from 'react';
import { BasePage } from '../../layouts';
import '../../styles/pages/HomePage.scss';
import Banner from './Banner';
import PartTVL from './PartTVL';
import PartRoadMap from './RoadMap';
import PartTokenomics from './Tokenomics';
import ScrollTriple from './ScrollTriple';
import Post from './Post';

const HomePage: FC = () => {
  const buyTokenRef = useRef<any>(null);

  const handleScrollClick = () => {
    if (!buyTokenRef) return;
    const divOffset = buyTokenRef?.current?.getBoundingClientRect().top;
    const offsetSmooth = divOffset + window.pageYOffset - 100;

    window.scrollTo({
      top: offsetSmooth,
      behavior: 'smooth',
    });
  };

  return (
    <BasePage>
      <Banner handleScrollClick={handleScrollClick} />
      <PartTVL buyTokenRef={buyTokenRef} />
      <PartTokenomics />
      <ScrollTriple />
      <Post />
      <PartRoadMap />
    </BasePage>
  );
};

export default HomePage;
