import React from 'react';
import { EPhaseStatus } from '../Mint';

const PhaseStatus: React.FC<{ status: EPhaseStatus }> = ({ status }) => {
  return (
    <div
      className={
        status === EPhaseStatus.COMING_SOON
          ? 'MintPage__mint__info__header__status--coming-soon'
          : status === EPhaseStatus.LIVE
          ? 'MintPage__mint__info__header__status--live'
          : 'MintPage__mint__info__header__status--completed'
      }
    >
      {status === EPhaseStatus.COMING_SOON
        ? 'Coming soon'
        : status === EPhaseStatus.LIVE
        ? 'Live'
        : 'Completed'}
    </div>
  );
};

export default PhaseStatus;
