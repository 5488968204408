import BaseRequest from './BaseRequest';
import config from '../config';

export default class AuthRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.baseUrlApi;
  }

  login(params: { walletAddress: string; signature: string }) {
    const url = `/auth/login`;
    return this.post(url, params);
  }

  getProfile() {
    const url = `/users/profile`;
    return this.get(url);
  }

  loginTwitter() {
    const url = `/auth/twitter`;
    return this.get(url);
  }
}
