import React from 'react';
import { FC } from 'react';
import '../../styles/pages/HomePage.scss';
import Marquee from 'react-fast-marquee';
import {
  IconPacmanYellow,
  IconPacmanBrown,
  IconPacmenBlue,
} from '../../assets/icons';

const ScrollTriple: FC = () => {
  return (
    <div className="banner-advertisement">
      <div className="advertisement">
        <div className="advertisement__orange">
          <Marquee
            speed={150}
            style={{
              height: '100%',
            }}
          >
            <div className="advertisement__list">
              <div className="advertisement__item">
                <IconPacmanYellow />
                <div className="advertisement__name">Rebel</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-red.png" />
                <div className="advertisement__name">Today</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-brown.png" />
                <div className="advertisement__name">Trailblazer</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-blue.png" />
                <div className="advertisement__name">Tomorrow</div>
              </div>
            </div>
            <div className="advertisement__list">
              <div className="advertisement__item">
                <IconPacmanYellow />
                <div className="advertisement__name">Rebel</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-red.png" />
                <div className="advertisement__name">Today</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-brown.png" />
                <div className="advertisement__name">Trailblazer</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-blue.png" />
                <div className="advertisement__name">Tomorrow</div>
              </div>
            </div>
            <div className="advertisement__list">
              <div className="advertisement__item">
                <IconPacmanYellow />
                <div className="advertisement__name">Rebel</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-red.png" />
                <div className="advertisement__name">Today</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-brown.png" />
                <div className="advertisement__name">Trailblazer</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-blue.png" />
                <div className="advertisement__name">Tomorrow</div>
              </div>
            </div>
            <div className="advertisement__list">
              <div className="advertisement__item">
                <IconPacmanYellow />
                <div className="advertisement__name">Rebel</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-red.png" />
                <div className="advertisement__name">Today</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-brown.png" />
                <div className="advertisement__name">Trailblazer</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-blue.png" />
                <div className="advertisement__name">Tomorrow</div>
              </div>
            </div>
          </Marquee>
        </div>
      </div>
      <div className="advertisement">
        <div className="advertisement__orange">
          <Marquee
            direction={'right'}
            style={{
              height: '100%',
            }}
            speed={150}
          >
            <div className="advertisement__list">
              <div className="advertisement__item">
                <IconPacmanYellow />
                <div className="advertisement__name">Rebel</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-red.png" />
                <div className="advertisement__name">Today</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-brown.png" />
                <div className="advertisement__name">Trailblazer</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-blue.png" />
                <div className="advertisement__name">Tomorrow</div>
              </div>
            </div>
            <div className="advertisement__list">
              <div className="advertisement__item">
                <IconPacmanYellow />
                <div className="advertisement__name">Rebel</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-red.png" />
                <div className="advertisement__name">Today</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-brown.png" />
                <div className="advertisement__name">Trailblazer</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-blue.png" />
                <div className="advertisement__name">Tomorrow</div>
              </div>
            </div>
            <div className="advertisement__list">
              <div className="advertisement__item">
                <IconPacmanYellow />
                <div className="advertisement__name">Rebel</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-red.png" />
                <div className="advertisement__name">Today</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-brown.png" />
                <div className="advertisement__name">Trailblazer</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-blue.png" />
                <div className="advertisement__name">Tomorrow</div>
              </div>
            </div>
            <div className="advertisement__list">
              <div className="advertisement__item">
                <IconPacmanYellow />
                <div className="advertisement__name">Rebel</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-red.png" />
                <div className="advertisement__name">Today</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-brown.png" />
                <div className="advertisement__name">Trailblazer</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-blue.png" />
                <div className="advertisement__name">Tomorrow</div>
              </div>
            </div>
          </Marquee>
        </div>
      </div>
      <div className="advertisement">
        <div className="advertisement__orange">
          <Marquee
            style={{
              height: '100%',
            }}
            speed={150}
          >
            <div className="advertisement__list">
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-brown.png" />
                <div className="advertisement__name">Trailblazer</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-red.png" />
                <div className="advertisement__name">Today</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-blue.png" />
                <div className="advertisement__name">Tomorrow</div>
              </div>
              <div className="advertisement__item">
                <IconPacmanYellow />
                <div className="advertisement__name">Rebel</div>
              </div>
            </div>
            <div className="advertisement__list">
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-brown.png" />
                <div className="advertisement__name">Trailblazer</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-blue.png" />
                <div className="advertisement__name">Tomorrow</div>
              </div>
              <div className="advertisement__item">
                <IconPacmanYellow />
                <div className="advertisement__name">Rebel</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-red.png" />
                <div className="advertisement__name">Today</div>
              </div>
            </div>
            <div className="advertisement__list">
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-brown.png" />
                <div className="advertisement__name">Trailblazer</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-red.png" />
                <div className="advertisement__name">Today</div>
              </div>
              <div className="advertisement__item">
                <IconPacmanYellow />
                <div className="advertisement__name">Rebel</div>
              </div>

              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-blue.png" />
                <div className="advertisement__name">Tomorrow</div>
              </div>
            </div>
            <div className="advertisement__list">
              <div className="advertisement__item">
                <IconPacmanYellow />
                <div className="advertisement__name">Rebel</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-brown.png" />
                <div className="advertisement__name">Trailblazer</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-red.png" />
                <div className="advertisement__name">Today</div>
              </div>
              <div className="advertisement__item">
                <img src="/images/home-page/icon-pacman-blue.png" />
                <div className="advertisement__name">Tomorrow</div>
              </div>
            </div>
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default ScrollTriple;
