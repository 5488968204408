import { createSlice } from '@reduxjs/toolkit';

export enum Field {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT',
}

export type SwapState = {
  independentField: string;
  typedValue: string;
  [Field.INPUT]: { currencyId: string | undefined };
  [Field.OUTPUT]: {
    currencyId: string | undefined;
  };
  // the typed recipient address or ENS name, or null if swap should go to sender
  recipient: string | null;
};

const initialState: SwapState = {
  independentField: Field.INPUT,
  typedValue: '',
  [Field.INPUT]: {
    currencyId: '',
  },
  [Field.OUTPUT]: {
    currencyId: '',
  },
  recipient: null,
};

const swapSlice = createSlice({
  name: 'swap',
  initialState,
  reducers: {
    setRecipient: (state, action) => {
      const { recipient } = action.payload;
      state.recipient = recipient;
    },
    replaceSwapState: (state, action) => {
      const {
        inputCurrencyId,
        outputCurrencyId,
        field,
        typedValue,
        recipient,
      } = action.payload;
      return {
        [Field.INPUT]: {
          currencyId: inputCurrencyId,
        },
        [Field.OUTPUT]: {
          currencyId: outputCurrencyId,
        },
        independentField: field,
        typedValue: typedValue,
        recipient,
      };
    },
    selectCurrency: (state, action) => {
      const { currencyId, field } = action.payload;
      const otherField = field === Field.INPUT ? Field.OUTPUT : Field.INPUT;
      if (currencyId === state[otherField].currencyId) {
        // the case where we have to swap the order
        return {
          ...state,
          independentField:
            state.independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT,
          [field]: { currencyId: currencyId },
          // @ts-ignore
          [otherField]: { currencyId: state[field].currencyId },
        };
      } else {
        // the normal case
        return {
          ...state,
          [field]: { currencyId: currencyId },
        };
      }
    },
    switchCurrencies: (state) => {
      return {
        ...state,
        independentField:
          state.independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT,
        [Field.INPUT]: { currencyId: state[Field.OUTPUT].currencyId },
        [Field.OUTPUT]: { currencyId: state[Field.INPUT].currencyId },
      };
    },
    typeInput: (state, action) => {
      const { field, typedValue } = action.payload;
      return {
        ...state,
        independentField: field,
        typedValue,
      };
    },
  },
});

export const {
  setRecipient,
  selectCurrency,
  switchCurrencies,
  replaceSwapState,
  typeInput,
} = swapSlice.actions;

export default swapSlice.reducer;
