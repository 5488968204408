import React from 'react';
import { IconTelegram, IconTwitter } from '../../assets/icons';
import config from '../../config';

const Banner = () => {
  return (
    <div className="marketplace__banner">
      <div className="marketplace__banner__image">
        <img src={'/images/marketplace/banner.png'} />
      </div>

      <div className="marketplace__banner__logo">
        <img src={'/images/marketplace/logo.png'} />
      </div>

      <div className="marketplace__banner__detail">
        <div>
          <div className="marketplace__banner__title">P@CMAN COLLECTION</div>
          <div className="marketplace__banner__desc">
            Created by <span>P@cman Blastoff</span>
          </div>
          <div className="marketplace__banner__desc">
            The P@cman NFT is like, a high five to all the peeps who vibe high
            and do their own thing, while they cruise through life's wild ride
            with a big ol' grin 🚬. It is your rad badge of honor, celebrating
            how you boldly go where no one's gone before, with a laid-back,
            fearless attitude that's just cruisin' through life, ya know?
          </div>
        </div>

        <div className="marketplace__banner__follow">
          Follow us on
          <div className="marketplace__banner__link">
            <a href={config.link.twitter} target="_blank">
              <IconTwitter />
            </a>
            <a href={config.link.telegram} target="_blank">
              <IconTelegram />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
