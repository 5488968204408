import { useSelector } from 'react-redux';
import { RootState } from '../index';
import { useActiveWeb3React } from '../../hooksUniswap';

export function useBlockNumber(): number | undefined {
  const { chainId } = useActiveWeb3React();

  return useSelector(
    (state: RootState) => state.application.blockNumber[chainId ?? -1],
  );
}
