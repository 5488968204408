import BaseModal from './BaseModal';
import React, { FC, useMemo } from 'react';
import '../styles/modals/Modal.scss';
import { IconClose } from '../assets/icons';
import { ICurrency } from '../config';
import { Trade } from '@blast/v2-sdk';
import SwapDetails from '../components/swap/SwapDetail';
import ButtonSwap from '../components/ButtonSwap';
import { Field } from '../store/swap';
import {
  computeTradePriceBreakdown,
  warningSeverity,
  computeSlippageAdjustedAmounts,
} from '../utils/swap/prices';

interface IModalConfirmSwap {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  currencyOutput: ICurrency;
  currencyInput: ICurrency;
  slippage: string;
  trade?: Trade;
}

const ModalConfirmSwap: FC<IModalConfirmSwap> = ({
  open,
  onClose,
  slippage,
  currencyInput,
  currencyOutput,
  trade,
  onConfirm,
}) => {
  const slippageAdjustedAmounts = useMemo(
    () => computeSlippageAdjustedAmounts(trade, +slippage),
    [trade, slippage],
  );

  const { priceImpactWithoutFee } = useMemo(
    () => computeTradePriceBreakdown(trade),
    [trade],
  );
  const severity = warningSeverity(priceImpactWithoutFee);

  return (
    <BaseModal open={open} isHideOverlay>
      <div className="modal-confirm-swap">
        <div className="modal__icon-close" onClick={onClose}>
          <IconClose />
        </div>

        <div className="modal__content">
          <div className="modal__content-title">Confirm Swap</div>

          <div className="modal-confirm-swap__content">
            <div className="modal-confirm-swap__token">
              <div className="modal-confirm-swap__token--left">
                {currencyInput.logoURI && (
                  <div>
                    <img width={'30px'} src={currencyInput.logoURI} />
                  </div>
                )}

                <div>{trade?.inputAmount.toSignificant(6)}</div>
              </div>
              <div>{currencyInput.symbol}</div>
            </div>
            <div className="modal-confirm-swap__token">
              <div className="modal-confirm-swap__token--left">
                {currencyOutput.logoURI && (
                  <div>
                    <img width={'30px'} src={currencyOutput.logoURI} />
                  </div>
                )}
                <div>{trade?.outputAmount.toSignificant(6)}</div>
              </div>
              <div>{currencyOutput.symbol}</div>
            </div>

            <div style={{ fontSize: '12px' }}>
              {`Output is estimated. You will receive at least `}
              <b>
                {slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(6)}{' '}
                {trade?.outputAmount.currency.symbol}
              </b>
              {' or the transaction will revert.'}
            </div>

            <div>
              <div
                className="swap__details--item"
                style={{ marginBottom: '5px', fontSize: '14px' }}
              >
                <div>Price</div>
                <div>
                  {trade?.executionPrice.toSignificant(6)}{' '}
                  {trade?.executionPrice?.quoteCurrency?.symbol} per{' '}
                  {trade?.executionPrice?.baseCurrency?.symbol}
                </div>
              </div>

              <SwapDetails
                isConfirm
                trade={trade}
                allowedSlippage={+slippage}
              />
            </div>

            <ButtonSwap onClick={onConfirm}>
              {severity > 2 ? 'Swap Anyway' : 'Confirm Swap'}
            </ButtonSwap>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default ModalConfirmSwap;
