import BaseModal from './BaseModal';
import React, { FC, useState, useEffect } from 'react';
import '../styles/modals/Modal.scss';
import { IconClose } from '../assets/icons';
import config, { ICurrency } from '../config';
import { formatNumber } from '../utils/format';
import { isAddress } from '@ethersproject/address';
import { useCurrencyBalance } from '../store/hooks/wallet';
import { useActiveWeb3React } from '../hooksUniswap';
import { useCurrency, useToken } from '../hooksUniswap/Tokens';

interface IModalSelectToken {
  open: boolean;
  onClose: () => void;
  onSelectCurrency: (currency: ICurrency) => void;
  otherCurrency: ICurrency;
}

const CurrencyItem = ({
  currency,
  onSelectCurrency,
  onClose,
  otherCurrency,
}: {
  currency: ICurrency;
  otherCurrency: ICurrency;
  onClose: () => void;
  onSelectCurrency: (currency: ICurrency) => void;
}) => {
  const { account } = useActiveWeb3React();
  const token = useCurrency(
    currency?.address
      ? currency?.address
      : currency?.symbol === 'ETH'
      ? 'ETH'
      : '',
  );

  const balance = useCurrencyBalance(account ?? undefined, token ?? undefined);
  const selected = otherCurrency.symbol === currency.symbol;

  const handleSelectCurrency = () => {
    if (selected) return;
    onSelectCurrency(currency);
    onClose();
  };

  return (
    <div
      className={`modal-select-token__currency ${selected ? 'selected' : ''}`}
      onClick={handleSelectCurrency}
    >
      <div>
        {currency?.logoURI && (
          <div className="modal-select-token__currency--logo">
            <img src={currency?.logoURI} />
          </div>
        )}

        <div>
          <span>{currency.symbol}</span>/ {currency.name}
        </div>
      </div>

      <div>{formatNumber(balance?.toSignificant(6) || '', 8)}</div>
    </div>
  );
};

const ModalSelectToken: FC<IModalSelectToken> = ({
  open,
  onClose,
  onSelectCurrency,
  otherCurrency,
}) => {
  const [valueSearch, setValueSearch] = useState<any>('');
  const [listToken, setListToken] = useState<any[]>([]);

  const searchToken = useToken(valueSearch);

  useEffect(() => {
    setListToken(config.tokens);
  }, []);

  useEffect(() => {
    let listFilter = config.tokens;

    if (!!valueSearch) {
      if (isAddress(valueSearch) && !!searchToken) {
        setListToken([searchToken]);
        return;
      }

      listFilter = listFilter.filter((item) => {
        return (
          item?.name?.toLowerCase().includes(valueSearch?.toLowerCase()) ||
          item?.symbol?.toLowerCase().includes(valueSearch?.toLowerCase())
        );
      });
    }

    setListToken(listFilter);
  }, [valueSearch, searchToken]);

  return (
    <BaseModal open={open} isHideOverlay>
      <div className="modal-select-token">
        <div className="modal__icon-close" onClick={onClose}>
          <IconClose />
        </div>

        <div className="modal__content">
          <div className="modal__content-title">Select a token</div>

          <div className="modal-select-token__input-search">
            <input
              placeholder="Search name or paste address"
              value={valueSearch}
              onChange={(e) => setValueSearch(e.target.value.trim())}
            />
          </div>

          <div style={{ width: '100%' }}>
            <div>Token Name</div>

            <div className="modal-select-token__list-currency">
              {!!listToken.length ? (
                listToken.map((item: ICurrency, index: number) => {
                  return (
                    <CurrencyItem
                      currency={item}
                      key={index}
                      onSelectCurrency={onSelectCurrency}
                      onClose={onClose}
                      otherCurrency={otherCurrency}
                    />
                  );
                })
              ) : (
                <div style={{ textAlign: 'center' }}>Not Found Token</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default ModalSelectToken;
