function importAll(context: any) {
  const images: any[] = [];
  context.keys().map((item: any) => {
    images.push(context(item));
  });
  return images;
}

export const pacmanNftGen0 = importAll(
  require.context('./gen-0', false, /\.(png|jpe?g|svg)$/),
);

export const pacmanNftGen1 = importAll(
  require.context('./gen-1', false, /\.(png|jpe?g|svg)$/),
);
