import React, { useState, ReactNode } from 'react';
import { copyToClipboard } from '../../utils/helper';
import {
  IconHowToBuy,
  IconCopy,
  IconPacmanHeader,
  IconDown,
  IconAva,
} from '../../assets/icons';
import '../../styles/pages/HomePage.scss';
import config from '../../config';
import PartSwap from './PartSwap';


const DATA = [
  {
    title: 'Forge your wallet',
    description:
      "Dive into the rebel's toolkit: snag MetaMask or any wallet that shouts 'freedom' from the App Store or Google Play - it's on the house. Desktop renegades, charge over to metamask.io and snag the Google Chrome extension. It's your first step to defiance.",
  },
  {
    title: 'Fuel up with ETH',
    description:
      'Power up your wallet with ETH to gear up for $PACM. No ETH? No sweat. Buy it straight from MetaMask, shuffle it over from another wallet, or snatch it from a different exchange and ship it to your digital fortress.',
  },
  {
    title: 'Storm Thruster Finance',
    description: (
      <div>
        Launch your assault on Thruster Finance. Hit up{' '}
        <a href={'https://app.thruster.finance/'} target="_blank">
          thruster.finance
        </a>{' '}
        via Google Chrome or through the portal in your MetaMask app. Link up
        your wallet like a digital lockpick. Drop the $PACM token address into
        Thruster Finance's heart, pick $PACM, and hammer that confirm button.
        When MetaMask hollers for a signature, brand it with yours.
      </div>
    ),
  },
  {
    title: 'Swap ETH for $PACM',
    description:
      'Flip ETH for $PACM like a true insurgent. We’re a tax-free zone - no need to fuss about specific slippage, though you might need to play with it when the market gets wild. Welcome to the rebellion.',
  },
];

interface IItemDescription {
  data: {
    title: string;
    description: ReactNode;
  };
  isActive: boolean;
  onOpen: () => void;
}

const ItemDescription = ({ data, isActive, onOpen }: IItemDescription) => {
  return (
    <div className="tvl__item border-style-game">
      <div className="tvl__item-title" onClick={onOpen}>
        <div>
          <IconPacmanHeader />
          <div>{data.title}</div>
        </div>

        <div className={isActive ? 'show' : ''}>
          <IconDown />
        </div>
      </div>

      {isActive && <div className="tvl__item-desc">{data.description}</div>}
    </div>
  );
};

const PartTVL = ({ buyTokenRef }: any) => {
  const [itemActive, setItemActive] = useState<number>(0);
  const TOKEN_ADDRESS = config.token_address;

  return (
    <div className="tvl">
      <div className="tvl__line-break"></div>
      <div className="tvl__content">
        <div className="tvl__cloud-left">
          <img src="/images/home-page/cloud-left.png" />
        </div>
        <div className="tvl__cloud-right">
          <img src="/images/home-page/cloud-right.png" />
        </div>
        <div className="tvl__title desktop">
          <img src="/images/home-page/heading-tvl.png" />
        </div>
        <div className="tvl__title mobile">
          <img src="/images/home-page/heading-tvl-mobile.png" />
        </div>
        <div className="tvl__image">
          <img src="/images/home-page/so-what.png" />
        </div>

        <div className="tvl__desc">
          <div>
            Get your adrenaline pumping and dive headfirst into this frenzy. The
            cash is gushing like a wild river, and meme tokens are skyrocketing
            in value by the nanosecond. And you still second thought $PACM?!
          </div>
          <div>
            Let's get one thing straight: $PACM and P@cman are two peas in a
            pod. $PACM is born to side with P@cman no matter what. And, you
            should do so, too. No joke, srly.
          </div>
          <div>
            FYI, with a stealth launch, no presale and the contract renounced,
            we're here for the sheer, unadulterated thrill of supporting P@cman
            Blastoff. If riding the wave of chaos with no string attached sounds
            like your jam, jump on board!
          </div>
        </div>

        <div className="tvl__map-bottom">
          <img src="/images/home-page/map-bottom-tvl.png" />
        </div>

        <div className="tvl__how-to-buy" ref={buyTokenRef}>
          <div className="tvl__buy-icon">
            <img src="/images/home-page/buy.gif" />
          </div>
          <div className="tvl__monster">
            <img src="/images/home-page/monster.png" />
          </div>
          <div className="tvl__how-to-buy-content">
            <div className="tvl__how-to-buy-map">
              <img src="/images/home-page/map-how-to-buy.png" />
            </div>
            <div className="tvl__how-to-buy--title">
              how to buy
              <IconHowToBuy />
            </div>

            <div className="tvl__how-to-buy--address">
              <div className="tvl__address-border top">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div className="tvl__address-content">
                <div>{TOKEN_ADDRESS}</div>
                <div onClick={() => copyToClipboard(TOKEN_ADDRESS)}>
                  <IconCopy />
                </div>
              </div>
              <div className="tvl__address-border bottom">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>

            <div className="tvl__list-item">
              {DATA.map((item, index) => {
                const isActive = index === itemActive;
                return (
                  <ItemDescription
                    key={index}
                    data={item}
                    isActive={isActive}
                    onOpen={() => setItemActive(index)}
                  />
                );
              })}
            </div>
          </div>
          <div className="tvl__how-to-buy-line">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="9"
              viewBox="0 0 14 9"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 0.323242C14 4.18924 10.866 7.32324 7 7.32324C3.13401 7.32324 0 4.18924 0 0.323242L0 8.32324H14V0.323242Z"
                fill="#000013"
              />
            </svg>

            <div className="tvl__how-to-buy-line-item">
              {Array.apply(0, Array(50)).map((item, index) => {
                return <div key={index}></div>;
              })}
            </div>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="9"
              viewBox="0 0 14 9"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 8.32324C14 4.45725 10.866 1.32324 7 1.32324C3.13401 1.32324 0 4.45725 0 8.32324L0 0.323242L14 0.323242V8.32324Z"
                fill="#000013"
              />
            </svg>
          </div>
          <div className="tvl__how-to-buy-line-mobile">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="15"
              viewBox="0 0 8 15"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 0.710449C3.86599 0.710449 7 3.84446 7 7.71045C7 11.5764 3.86599 14.7104 0 14.7104H8V0.710449H0Z"
                fill="#000013"
              />
            </svg>

            <div className="tvl__how-to-buy-line-mobile-item">
              {Array.apply(0, Array(50)).map((item, index) => {
                return <div key={index}></div>;
              })}
            </div>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="15"
              viewBox="0 0 8 15"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 14.7104C4.13401 14.7104 1 11.5764 1 7.71045C1 3.84446 4.13401 0.710449 8 0.710449H0V14.7104H8Z"
                fill="#000013"
              />
            </svg>
          </div>
          <div className="tvl__how-to-buy-uniswap">
            <div className="tvl__how-to-buy-uniswap--content">
              <PartSwap />
            </div>
          </div>
        </div>
        <div className="banner__btn" onClick={() => window.open('https://t.me/AVATradingBlast_Bot', '_blank')}>
          <div className="banner__btn-icon-left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="9"
              viewBox="0 0 14 9"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.29094 0H0.960938V3.33333H4.29094V0ZM6.96094 2H10.2943V5.33334H13.6276V8.66668H6.96094V8.66667V5.33334V2Z"
                fill="white"
              />
            </svg>
          </div>
          <IconAva /> Snipe faster with Avabot
          <div className="banner__btn-icon-right">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="7"
              viewBox="0 0 15 7"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.168 0H10.8359H3.83464H0.169271V3.33333H3.83464V3.33334H3.83333V6.66668H14.5V3.33334H11.168V0Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartTVL;
