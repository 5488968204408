import BaseModal from './BaseModal';
import React, { FC } from 'react';
import '../styles/modals/Modal.scss';
import GroupFilter from '../pages/Marketplace/GroupFilter';

interface IModalFilter {
  open: boolean;
  onClose: () => void;
  params: any;
  setParams: any;
}

const ModalFilter: FC<IModalFilter> = ({
  open,
  onClose,
  params,
  setParams,
}) => {
  return (
    <BaseModal open={open} isHideOverlay className="modal-filter">
      <div>
        <GroupFilter setParams={setParams} params={params} />
      </div>

      <div className="modal-filter__icon-close" onClick={onClose}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
        >
          <path
            d="M5.98964 4.46876L20.1318 18.6109L18.7176 20.0251L4.57542 5.88298L5.98964 4.46876Z"
            fill="white"
          />
          <path
            d="M3.86829 18.6109L18.0104 4.46875L19.4246 5.88296L5.2825 20.0251L3.86829 18.6109Z"
            fill="white"
          />
        </svg>
      </div>
    </BaseModal>
  );
};

export default ModalFilter;
