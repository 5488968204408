export const GAMES = {
  GAME_EGG_COLLECTOR: 'GameEggCollector',
  GAME_TOWER_OF_GHOST: 'GameTowerOfGhost',
};

export const NOT_AVAILABLE_TEXT = '--';

export const SI = [
  { value: 1, symbol: '' },
  { value: 1e3, symbol: 'K' },
  { value: 1e6, symbol: 'M' },
  { value: 1e9, symbol: 'B' },
  { value: 1e12, symbol: 't' },
  { value: 1e15, symbol: 'q' },
  { value: 1e18, symbol: 'Q' },
  { value: 1e21, symbol: 's' },
  { value: 1e24, symbol: 'S' },
  { value: 1e27, symbol: 'o' },
  { value: 1e30, symbol: 'n' },
  { value: 1e33, symbol: 'd' },
  { value: 1e36, symbol: 'U' },
  { value: 1e39, symbol: 'D' },
  { value: 1e42, symbol: 'T' },
  { value: 1e45, symbol: 'Qt' },
  { value: 1e48, symbol: 'Qd' },
  { value: 1e51, symbol: 'Sd' },
  { value: 1e54, symbol: 'St' },
  { value: 1e57, symbol: 'O' },
  { value: 1e60, symbol: 'N' },
  { value: 1e63, symbol: 'v' },
  { value: 1e66, symbol: 'c' },
];

export const TO_BE_ANNOUCED_TEXT = 'TBA';