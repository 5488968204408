import { combineReducers, configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import user from '../store/user';
import game from '../store/game';
import transactions from '../store/transactions';
import swap from '../store/swap';
import userSwap from '../store/userSwap';
import multicall from '../store/multicall';
import application from '../store/application';
import transactionSwap from '../store/transactionSwap';
import lists from '../store/lists';

const rootReducer = combineReducers({
  user,
  game,
  transactions,
  swap,
  userSwap,
  multicall,
  application,
  transactionSwap,
  lists,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunkMiddleware],
});

export type RootState = ReturnType<typeof store.getState>;

export default () => {
  return { store };
};
