import { createSlice } from '@reduxjs/toolkit';

export interface Call {
  address: string;
  callData: string;
}

const ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/;
const LOWER_HEX_REGEX = /^0x[a-f0-9]*$/;

export function toCallKey(call: Call): string {
  if (!ADDRESS_REGEX.test(call.address)) {
    throw new Error(`Invalid address: ${call.address}`);
  }
  if (!LOWER_HEX_REGEX.test(call.callData)) {
    throw new Error(`Invalid hex: ${call.callData}`);
  }
  return `${call.address}-${call.callData}`;
}

export function parseCallKey(callKey: string): Call {
  const pcs = callKey.split('-');
  if (pcs.length !== 2) {
    throw new Error(`Invalid call key: ${callKey}`);
  }
  return {
    address: pcs[0],
    callData: pcs[1],
  };
}

export interface ListenerOptions {
  // how often this data should be fetched, by default 1
  readonly blocksPerFetch?: number;
}

export type MulticallState = {
  callListeners?: {
    // on a per-chain basis
    [chainId: number]: {
      // stores for each call key the listeners' preferences
      [callKey: string]: {
        // stores how many listeners there are per each blocks per fetch preference
        [blocksPerFetch: number]: number;
      };
    };
  };

  callResults: {
    [chainId: number]: {
      [callKey: string]: {
        data?: string | null;
        blockNumber?: number;
        fetchingBlockNumber?: number;
      };
    };
  };
};

const initialState: MulticallState = {
  callResults: {},
};

const multicallSlice = createSlice({
  name: 'multicall',
  initialState,
  reducers: {
    addMulticallListeners: (
      state,
      { payload: { calls, chainId, options: { blocksPerFetch = 1 } = {} } },
    ) => {
      const listeners: MulticallState['callListeners'] = state.callListeners
        ? state.callListeners
        : (state.callListeners = {});
      listeners[chainId] = listeners[chainId] ?? {};
      calls.forEach((call: any) => {
        const callKey = toCallKey(call);
        listeners[chainId][callKey] = listeners[chainId][callKey] ?? {};
        listeners[chainId][callKey][blocksPerFetch] =
          (listeners[chainId][callKey][blocksPerFetch] ?? 0) + 1;
      });
    },
    removeMulticallListeners: (
      state,
      { payload: { chainId, calls, options: { blocksPerFetch = 1 } = {} } },
    ) => {
      const listeners: MulticallState['callListeners'] = state.callListeners
        ? state.callListeners
        : (state.callListeners = {});

      if (!listeners[chainId]) return;
      calls.forEach((call: any) => {
        const callKey = toCallKey(call);
        if (!listeners[chainId][callKey]) return;
        if (!listeners[chainId][callKey][blocksPerFetch]) return;

        if (listeners[chainId][callKey][blocksPerFetch] === 1) {
          delete listeners[chainId][callKey][blocksPerFetch];
        } else {
          listeners[chainId][callKey][blocksPerFetch]--;
        }
      });
    },
    fetchingMulticallResults: (
      state,
      { payload: { chainId, fetchingBlockNumber, calls } },
    ) => {
      state.callResults[chainId] = state.callResults[chainId] ?? {};
      calls.forEach((call: any) => {
        const callKey = toCallKey(call);
        const current = state.callResults[chainId][callKey];
        if (!current) {
          state.callResults[chainId][callKey] = {
            fetchingBlockNumber,
          };
        } else {
          if ((current.fetchingBlockNumber ?? 0) >= fetchingBlockNumber) return;
          state.callResults[chainId][callKey].fetchingBlockNumber =
            fetchingBlockNumber;
        }
      });
    },
    errorFetchingMulticallResults: (
      state,
      { payload: { fetchingBlockNumber, chainId, calls } },
    ) => {
      state.callResults[chainId] = state.callResults[chainId] ?? {};
      calls.forEach((call: any) => {
        const callKey = toCallKey(call);
        const current = state.callResults[chainId][callKey];
        if (!current) return; // only should be dispatched if we are already fetching
        if (current.fetchingBlockNumber === fetchingBlockNumber) {
          delete current.fetchingBlockNumber;
          current.data = null;
          current.blockNumber = fetchingBlockNumber;
        }
      });
    },
    updateMulticallResults: (
      state,
      { payload: { chainId, results, blockNumber } },
    ) => {
      state.callResults[chainId] = state.callResults[chainId] ?? {};
      Object.keys(results).forEach((callKey) => {
        const current = state.callResults[chainId][callKey];
        if ((current?.blockNumber ?? 0) > blockNumber) return;
        state.callResults[chainId][callKey] = {
          data: results[callKey],
          blockNumber,
        };
      });
    },
  },
});

export const {
  addMulticallListeners,
  removeMulticallListeners,
  fetchingMulticallResults,
  errorFetchingMulticallResults,
  updateMulticallResults,
} = multicallSlice.actions;

export default multicallSlice.reducer;
