import React, { useState } from 'react';
import { IconUnCheckBox, IconCheckedBox, ArrowLight } from '../../assets/icons';
import rf from '../../services/RequestFactory';
import { useEffectUnsafe } from '../../hooks/useEffectUnsafe';

const FilterItem = ({ item, params, setParams }: any) => {
  const [isShow, setIsShow] = useState<boolean>(true);
  const [value, setValue] = useState<string>('');

  useEffectUnsafe(() => {
    if (!value) return;
    params[item.key] = value;
    setParams({ ...params });
  }, [value]);

  return (
    <div className="marketplace__filter-item">
      <div
        className="marketplace__filter-item__name"
        onClick={() => setIsShow(!isShow)}
      >
        {item.name} <ArrowLight />
      </div>

      {isShow && (
        <div className="marketplace__filter-item__options">
          {item?.option?.map((option: string, index: number) => {
            return (
              <div className="marketplace__filter-item__option" key={index}>
                <div
                  onClick={() => {
                    if (value === option) {
                      setValue('');
                      params[item.key] = '';
                      setParams({ ...params });
                      return;
                    }

                    setValue(option);
                  }}
                >
                  {option === params[item.key] ? (
                    <IconCheckedBox />
                  ) : (
                    <IconUnCheckBox />
                  )}
                  {option}
                </div>
                <div></div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const GroupFilter = ({ params, setParams }: any) => {
  const [dataFilter, setDataFilter] = useState<any>([]);

  const getDistinctField = async () => {
    try {
      const res = await rf.getRequest('NFTRequest').getDistinctField();
      setDataFilter(res);
    } catch (error) {
      setDataFilter([]);
    }
  };

  useEffectUnsafe(() => {
    getDistinctField().then();
  }, []);

  return (
    <div className="marketplace__group-filter">
      <div className="marketplace__group-filter__content">
        {!!dataFilter.length &&
          dataFilter?.map((item: any, index: number) => {
            return (
              <FilterItem
                item={item}
                key={index}
                params={params}
                setParams={setParams}
              />
            );
          })}
      </div>
    </div>
  );
};

export default GroupFilter;
