import { createSlice } from '@reduxjs/toolkit';

export type TransactionsState = {
  data: any;
  isOpenModalSubmitting: boolean;
  isOpenModalSubmitted: boolean;
};

const initialState: TransactionsState = {
  data: {},
  isOpenModalSubmitted: false,
  isOpenModalSubmitting: false,
};

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setDataTransaction: (state, action) => {
      state.data = action.payload;
    },
    setIsOpenModalSubmitting: (state, action) => {
      state.isOpenModalSubmitting = action.payload;
    },
    setIsOpenModalSubmitted: (state, action) => {
      state.isOpenModalSubmitted = action.payload;
    },
  },
});

export const {
  setDataTransaction,
  setIsOpenModalSubmitted,
  setIsOpenModalSubmitting,
} = transactionsSlice.actions;

export default transactionsSlice.reducer;
