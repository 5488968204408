import React, { FC, useState, useEffect, useCallback } from 'react';
import '../../styles/pages/HomePage.scss';
import CurrencyInput from '../../components/swap/CurrencyInput';
import { IconDown, IconSetting } from '../../assets/icons';
import config, { ICurrency } from '../../config';
import {
  ModalSlippage,
  ModalConfirmSwap,
  ModalTransactionSwap,
} from '../../modals';
import {
  useSwapActionHandlers,
  useSwapState,
  useDerivedSwapInfo,
} from '../../store/hooks/swap';
import { Field } from '../../store/swap';
import { useActiveWeb3React } from '../../hooksUniswap';
import useWrapCallback, { WrapType } from '../../hooksUniswap/useWrapCallback';
import useENSAddress from '../../hooksUniswap/useENSAddress';
import {
  useApproveCallbackFromTrade,
  ApprovalState,
} from '../../hooksUniswap/useApproveCallback';
import { maxAmountSpend } from '../../utils/swap/maxAmountSpend';
import { JSBI } from '@blast/v2-sdk';
import SwapDetails from '../../components/swap/SwapDetail';
import {
  computeTradePriceBreakdown,
  warningSeverity,
} from '../../utils/swap/prices';
import { useSwapCallback } from '../../hooksUniswap/useSwapCallback';
import confirmPriceImpactWithoutFee from '../../utils/swap/confirmPriceImpactWithoutFee';
import { DEFAULT_DEADLINE_FROM_NOW } from '../../constants';
import ButtonSwap from '../../components/ButtonSwap';
import { useDispatch } from 'react-redux';
import { setIsOpenModalConnectWallet } from '../../store/user';
import { useEffectUnsafe } from '../../hooks/useEffectUnsafe';

const PartSwap: FC = () => {
  const [currencyInput, setCurrencyInput] = useState<ICurrency>({});
  const [currencyOutput, setCurrencyOutput] = useState<ICurrency>({});
  const [slippage, setSlippage] = useState<string>('500');
  const [isShowModalSlippage, setIsShowModalSlippage] =
    useState<boolean>(false);
  const [isShowModalConfirm, setIsShowModalConfirm] = useState<boolean>(false);
  const [isShowModalTransaction, setIsShowModalTransaction] =
    useState<boolean>(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState<boolean>(false);
  const [swapErrorMessage, setSwapErrorMessage] = useState<string>('');
  const [txHash, setTxHash] = useState<string>('');

  const { account } = useActiveWeb3React();
  const dispatch = useDispatch();

  useEffectUnsafe(() => {
    if (isShowModalConfirm || isShowModalTransaction || isShowModalSlippage) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'inherit';
    }
  }, [isShowModalConfirm, isShowModalTransaction, isShowModalSlippage]);

  const {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient,
  } = useSwapActionHandlers();

  const { independentField, typedValue, recipient } = useSwapState();

  const {
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo();

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue,
  );
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE;

  const { address: recipientAddress } = useENSAddress(recipient);
  const trade = showWrap ? undefined : v2Trade;

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount,
      }
    : {
        [Field.INPUT]:
          independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]:
          independentField === Field.OUTPUT
            ? parsedAmount
            : trade?.outputAmount,
      };

  const isValid = !swapInputError;
  const dependentField: Field =
    independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT;

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? // @ts-ignore
        parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  };

  const route = trade?.route;
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] &&
      currencies[Field.OUTPUT] &&
      // @ts-ignore
      parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0)),
  );

  const noRoute = !route;

  // Approve
  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(
    trade,
    +slippage,
  );

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false);

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true);
    }
  }, [approval, approvalSubmitted]);

  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED));

  // Approve

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade);

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee);

  const maxAmountInput: any = maxAmountSpend(currencyBalances[Field.INPUT]);

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    trade,
    +slippage,
    DEFAULT_DEADLINE_FROM_NOW,
    recipient,
  );

  useEffect(() => {
    const defaultToken = config.tokens;
    setCurrencyInput(defaultToken[0]);
    setCurrencyOutput(defaultToken[1]);
    onCurrencySelection(Field.INPUT, defaultToken[0] as any);
    onCurrencySelection(Field.OUTPUT, defaultToken[1] as any);
  }, []);

  const handleSetMax = () => {
    onUserInput(Field.INPUT, maxAmountInput?.toExact());
  };

  const handleTypeInput = (value: string) => {
    onUserInput(Field.INPUT, value);
  };

  const handleTypeOutput = (value: string) => {
    onUserInput(Field.OUTPUT, value);
  };

  // const handleSelectCurrencyOutput = (currency: any) => {
  //   setCurrencyOutput(currency);
  //   onCurrencySelection(Field.OUTPUT, currency);
  // };
  //
  // const handleSelectCurrencyInput = (currency: any) => {
  //   setCurrencyInput(currency);
  //   onCurrencySelection(Field.INPUT, currency);
  // };

  const onSwitchCurrency = () => {
    onSwitchTokens();
    setCurrencyInput(currencyOutput);
    setCurrencyOutput(currencyInput);
  };

  const handleSwap = useCallback(() => {
    if (
      priceImpactWithoutFee &&
      !confirmPriceImpactWithoutFee(priceImpactWithoutFee)
    ) {
      return;
    }

    if (!swapCallback) {
      return;
    }

    setIsShowModalConfirm(false);
    setIsLoadingConfirm(true);
    setIsShowModalTransaction(true);
    swapCallback()
      .then((hash) => {
        setIsLoadingConfirm(false);
        setTxHash(hash);
      })
      .catch((error) => {
        setIsShowModalConfirm(false);
        setIsLoadingConfirm(false);
        setSwapErrorMessage(error.message);
      });
  }, [
    account,
    priceImpactWithoutFee,
    recipient,
    recipientAddress,
    swapCallback,
    trade,
  ]);

  const _renderButton = () => {
    if (!account) {
      return (
        <ButtonSwap onClick={() => dispatch(setIsOpenModalConnectWallet(true))}>
          Connect wallet
        </ButtonSwap>
      );
    }

    if (showWrap) {
      return (
        <ButtonSwap onClick={onWrap} disabled={Boolean(wrapInputError)}>
          {wrapInputError ??
            (wrapType === WrapType.WRAP
              ? 'Wrap'
              : wrapType === WrapType.UNWRAP
              ? 'Unwrap'
              : null)}
        </ButtonSwap>
      );
    }

    if (noRoute && userHasSpecifiedInputOutput) {
      return (
        <ButtonSwap disabled>Insufficient liquidity for this trade.</ButtonSwap>
      );
    }

    if (swapInputError) {
      return (
        <ButtonSwap disabled={!!swapInputError}>{swapInputError}</ButtonSwap>
      );
    }

    if (showApproveFlow) {
      return (
        <ButtonSwap
          diabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
          onClick={approveCallback}
        >
          {approval === ApprovalState.PENDING
            ? 'Approving'
            : approvalSubmitted && approval === ApprovalState.APPROVED
            ? 'Approved'
            : 'Approve ' + currencies[Field.INPUT]?.symbol}
        </ButtonSwap>
      );
    }

    return (
      <ButtonSwap
        onClick={() => setIsShowModalConfirm(true)}
        disabled={
          !isValid ||
          approval !== ApprovalState.APPROVED ||
          priceImpactSeverity > 3
        }
      >
        {priceImpactSeverity > 3
          ? `Price Impact High`
          : `Swap${priceImpactSeverity > 2 ? ' Anyway' : ''}`}
      </ButtonSwap>
    );
  };

  return (
    <div className="swap">
      <div className="swap__title">
        <div>
          Swap powered by{' '}
          <a href={'https://www.thruster.finance/'} target="_blank">
            thruster.finance
          </a>
        </div>

        <div
          className="swap__setting"
          onClick={() => setIsShowModalSlippage(true)}
        >
          <IconSetting />
        </div>
      </div>

      <CurrencyInput
        label="From"
        showMaxButton={!!currencies[Field.INPUT] && !!account}
        value={formattedAmounts[Field.INPUT]}
        onChange={handleTypeInput}
        onMax={handleSetMax}
        currency={currencyInput}
        currencySelected={currencies[Field.INPUT]}
        // onSelectCurrency={handleSelectCurrencyInput}
        otherCurrency={currencyOutput}
      />

      <div className="swap__icon-down" onClick={onSwitchCurrency}>
        <IconDown />
      </div>

      <CurrencyInput
        label="To"
        value={formattedAmounts[Field.OUTPUT]}
        onChange={handleTypeOutput}
        currencySelected={currencies[Field.OUTPUT]}
        currency={currencyOutput}
        otherCurrency={currencyInput}
        // onSelectCurrency={handleSelectCurrencyOutput}
      />

      {Boolean(trade) && (
        <div className="swap__price">
          <div>Price</div>
          <div>
            {trade?.executionPrice.toSignificant(6)}{' '}
            {trade?.executionPrice?.quoteCurrency?.symbol} per{' '}
            {trade?.executionPrice?.baseCurrency?.symbol}
          </div>
        </div>
      )}

      <div className="swap__slippage">
        <div>Slippage Tolerance</div>
        <div>{+slippage / 100} %</div>
      </div>

      <div className="swap__btn">{_renderButton()}</div>

      <SwapDetails trade={trade} allowedSlippage={+slippage} />

      {isShowModalSlippage && (
        <ModalSlippage
          slippage={slippage}
          onSetSlippage={setSlippage}
          open={isShowModalSlippage}
          onClose={() => setIsShowModalSlippage(false)}
        />
      )}

      {isShowModalConfirm && (
        <ModalConfirmSwap
          onConfirm={handleSwap}
          trade={trade}
          currencyInput={currencyInput}
          currencyOutput={currencyOutput}
          open={isShowModalConfirm}
          slippage={slippage}
          onClose={() => setIsShowModalConfirm(false)}
        />
      )}

      {isShowModalTransaction && (
        <ModalTransactionSwap
          swapErrorMessage={swapErrorMessage}
          isLoading={isLoadingConfirm}
          onClose={() => setIsShowModalTransaction(false)}
          open={isShowModalTransaction}
          trade={trade}
          hash={txHash}
        />
      )}
    </div>
  );
};

export default PartSwap;
