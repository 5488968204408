import React, { FC, useMemo, useState, useCallback } from 'react';
import '../../../styles/pages/MintPage.scss';
import CountDownBox from './CountdownBox';
import PhaseStatus from './PhaseStatus';
import {
  ConnectWalletBtn,
  EPhaseStatus,
  MintBtn,
  NotWhitelistedBtn,
} from '../Mint';
import { useActiveWeb3React } from '../../../hooksUniswap';
import { setIsOpenModalConnectWallet } from '../../../store/user';
import { useMintNftGen1Callback } from '../../../hooksUniswap/useMintNftCallback';
import config from '../../../config';
import { useDispatch } from 'react-redux';
import { ModalTransactionProcess } from '../../../modals';
import About from './About';
import rf from '../../../services/RequestFactory';
import { useEffectUnsafe } from '../../../hooks/useEffectUnsafe';
import moment from 'moment';
import { useGen1NftContract } from '../../../hooksUniswap/useContract';

const GEN1_NFT_CONTRACT_ADDRESS = config.contracts.gen1_nft_contract;

const Phase1: FC<{
  phaseStatus: EPhaseStatus;
  dueDate: number;
}> = ({ phaseStatus, dueDate }) => {
  const { account } = useActiveWeb3React();
  const [isShowModalTransaction, setIsShowModalTransaction] =
    useState<boolean>(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState<boolean>(false);
  const [signature, setSignature] = useState<string>('');
  const [isMinted, setIsMinted] = useState<boolean>(true);
  const [txHashInfo, setTxHashInfo] = useState<{
    hash: string;
    errorMessage?: string;
  }>();
  const nftContract = useGen1NftContract(GEN1_NFT_CONTRACT_ADDRESS);

  const checkMintedNFT = useCallback(async () => {
    if (!account) return 0;
    const isMintedNFT = await nftContract?.mintedAddress(account);
    setIsMinted(isMintedNFT);
  }, [account]);

  useEffectUnsafe(() => {
    checkMintedNFT().then();
  }, [account]);

  const dispatch = useDispatch();

  const onConnectWallet = () => {
    dispatch(setIsOpenModalConnectWallet(true));
  };

  const [mintCallback] = useMintNftGen1Callback(
    GEN1_NFT_CONTRACT_ADDRESS,
    signature,
  );

  const onMint = async () => {
    setIsLoadingConfirm(true);
    setIsShowModalTransaction(true);

    const onMintHandle = () => {
      return mintCallback()
        .then(async (hash) => {
          await new Promise((r) => setTimeout(r, 5 * config.block_time * 1000));
          await checkMintedNFT().then();
          setIsLoadingConfirm(false);
          setTxHashInfo({ hash });
        })
        .catch((error) => {
          setIsLoadingConfirm(false);
          setTxHashInfo((prev: any) => ({
            ...prev,
            errorMessage: error.message,
          }));
        });
    };

    return onMintHandle();
  };

  const disableMint = useMemo(() => {
    return !account || phaseStatus !== EPhaseStatus.LIVE || isMinted;
  }, [account, phaseStatus, isMinted]);

  const getSignatureGen1 = async () => {
    try {
      const res = await rf.getRequest('BurnRequest').getSignatureGen1(account);
      if (res && res?.signature) {
        setSignature(res?.signature);
      }
    } catch (e) {
      setSignature('');
      console.error(e);
    }
  };

  useEffectUnsafe(() => {
    getSignatureGen1().then();
  }, [account]);

  return (
    <div className="MintPage__mint__info">
      <div className="MintPage__mint__info__noti">
        {phaseStatus === EPhaseStatus.COMING_SOON
          ? 'MINTING IS NOT START YET'
          : 'MINTING NOW'}
      </div>

      <div className="MintPage__mint__info__header">
        <div className="MintPage__mint__info__header__phase">
          Phase 2: Gen1 - Pixelated Party Leaves
          <div className="MintPage__mint__info__header__status">
            <PhaseStatus status={phaseStatus} />
          </div>
        </div>
      </div>

      <div className="MintPage__mint__info__description">
        If you're rolling in the top 2800 squad burning tokens like it's a
        bonfire, or you're one of the elite 100 rocking points in social event
        002, you've earned your right to mint that NFT Gen1, fam!
      </div>

      <About />

      {dueDate && (
        <div className="MintPage__mint__info__countdown--coming-soon">
          <div className="MintPage__mint__info__countdown--coming-soon__text">
            {phaseStatus === EPhaseStatus.COMING_SOON
              ? `Mint Process will start in:`
              : 'Mint Process will end in:'}
          </div>

          <CountDownBox
            phaseStatus={phaseStatus}
            dueDate={moment(dueDate).format('YYYY/MM/DD HH:mm:ss')}
          />
        </div>
      )}

      <div className="MintPage__mint__info__mint-amount">
        <div className="MintPage__mint__info__connect-wallet">
          {!account ? (
            <>
              <ConnectWalletBtn onConnectWallet={onConnectWallet} />
              <div className="MintPage__mint__info__connect-wallet__warning">
                Please Connect your Wallet before minting
              </div>
            </>
          ) : !signature ? (
            <NotWhitelistedBtn />
          ) : (
            <MintBtn disabled={disableMint} onMint={onMint} />
          )}
        </div>
      </div>

      <ModalTransactionProcess
        isLoading={isLoadingConfirm}
        onClose={() => setIsShowModalTransaction(false)}
        open={isShowModalTransaction}
        txHashInfo={txHashInfo}
      />
    </div>
  );
};

export default Phase1;
