import React, { FC, useEffect, useState } from 'react';
import {
  IconTelegram,
  IconTwitter,
  IconMenu,
  IconCloseMenu,
} from '../assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { setIsOpenModalConnectWallet } from '../store/user';
import { Link, useLocation } from 'react-router-dom';
import { useEffectUnsafe } from '../hooks/useEffectUnsafe';
import config from '../config';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { formatShortAddress } from '../utils/format';
import { ModalConnectWallet } from '../modals';
import { useETHBalances } from '../store/hooks/wallet';

const TOKEN_CONTRACT_ADDRESS = '0x0b4d0ee29857c3961b380d4ec138ea5814e346b9';

const MENU = [
  {
    name: 'Home',
    path: '/',
  },
  {
    name: 'Arcade Center',
    path: 'https://arcade.pacman.meme',
  },
  {
    name: '🏭 AIRDROP 002',
    path: 'https://airdrop.pacman.meme',
  },
  {
    name: 'Mint NFT',
    path: '/mint',
  },
  {
    name: '🛍️ MARKETPLACE',
    path: '/marketplace',
  },
  {
    name: 'My NFT',
    path: '/my-nft',
  },
];

const Header: FC = () => {
  const { isOpenModalConnectWallet } = useSelector(
    (state: RootState) => state.user,
  );
  const [sticky, setSticky] = useState('');
  const [isShowMenu, setIsShowMenu] = useState<boolean>(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const link = config.link;
  const { account, error } = useWeb3React();

  const userEthBalance = useETHBalances(account ? [account] : [])?.[
    account ?? ''
  ];

  useEffect(() => {
    window.addEventListener('scroll', isSticky);

    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  }, []);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 1 ? 'fixed' : '';
    setSticky(stickyClass);
  };

  useEffectUnsafe(() => {
    if (isShowMenu || isOpenModalConnectWallet) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'inherit';
    }
  }, [isShowMenu, isOpenModalConnectWallet]);

  const onConnectWallet = () => {
    dispatch(setIsOpenModalConnectWallet(true));
  };

  const _renderAccount = () => {
    if (account) {
      return (
        <div className="header__connect-btn active" onClick={onConnectWallet}>
          <div>{userEthBalance?.toSignificant(4) || '--'} ETH</div>
          <div>{formatShortAddress(account)}</div>
        </div>
      );
    } else if (error) {
      return (
        <div className="header__connect-btn wrong" onClick={onConnectWallet}>
          {error instanceof UnsupportedChainIdError ? 'Wrong Network' : 'Error'}
        </div>
      );
    }

    return (
      // TODO: update
      <div className="header__connect-btn" onClick={onConnectWallet}>
        Connect Wallet
      </div>
    );
  };

  return (
    <div
      className={`header ${sticky} ${
        location.pathname === '/arcade-center' ? 'fixed' : ''
      }`}
    >
      <div className="header__content">
        <div className="header__menu--left">
          <div className="header__logo">
            <img src={'/images/logo.gif'} />
          </div>

          <div className="header__menu">
            {MENU.map((item, index) => {
              if (!account && item.name === 'My NFT') return;
              return (
                <a href={item.path} key={index}>
                  <div
                    className={`header__menu-item ${
                      location.pathname === item.path ? 'active' : ''
                    } ${
                      item.path === 'https://airdrop.pacman.meme'
                        ? 'menu-factory'
                        : ''
                    }`}
                  >
                    {item.name}
                  </div>
                </a>
              );
            })}

            {/*<a href={config.link.dextools} target="_blank">*/}
            {/*  <div className="header__menu-item">*/}
            {/*    <img src={'/images/icon-dextools.png'} />*/}
            {/*    Dextools*/}
            {/*  </div>*/}
            {/*</a>*/}
          </div>
        </div>

        <div className="header__menu--right">
          <div className="header__menu">
            <a
              href={`${config.network_explorer}/address/${TOKEN_CONTRACT_ADDRESS}`}
              target="_blank"
            >
              <div className="header__btn-ca">CA: {TOKEN_CONTRACT_ADDRESS}</div>
            </a>
          </div>

          <a href={link.twitter} target="_blank" title={'Twitter'}>
            <div className="header__menu-social">
              <IconTwitter />
            </div>
          </a>
          <a href={link.telegram} target="_blank" title={'Telegram'}>
            <div className="header__menu-social">
              <IconTelegram />
            </div>
          </a>

          <div className="header__menu">{_renderAccount()}</div>

          <div
            className="header__menu-btn"
            onClick={() => setIsShowMenu(!isShowMenu)}
          >
            {isShowMenu ? <IconCloseMenu /> : <IconMenu />}
          </div>
        </div>
      </div>

      {isShowMenu && (
        <div className="header__menu-mobile">
          <div className="header__menu">
            {MENU.map((item, index) => {
              if (!account && item.name === 'My NFT') return;
              return (
                <a href={item.path} key={index} title={item.name}>
                  <div
                    className={`header__menu-item ${
                      location.pathname === item.path ? 'active' : ''
                    }`}
                  >
                    {item.name}
                  </div>
                </a>
              );
            })}
          </div>

          {/*<a href={config.link.dextools} target="_blank">*/}
          {/*  <div className="header__menu-item">*/}
          {/*    <img src={'/images/icon-dextools.png'} />*/}
          {/*    Dextools*/}
          {/*  </div>*/}
          {/*</a>*/}

          {_renderAccount()}

          <a
            href={`${config.network_explorer}/address/${TOKEN_CONTRACT_ADDRESS}`}
            target="_blank"
          >
            <div className="header__btn-ca">CA: {TOKEN_CONTRACT_ADDRESS}</div>
          </a>
        </div>
      )}

      {isShowMenu && (
        <div
          onClick={() => setIsShowMenu(!isShowMenu)}
          className="header__bg"
        />
      )}

      {isOpenModalConnectWallet && (
        <ModalConnectWallet open={isOpenModalConnectWallet} />
      )}
    </div>
  );
};

export default Header;
