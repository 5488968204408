import React, { FC, useEffect, useMemo, useState } from 'react';
import '../../../styles/pages/MintPage.scss';
import CountDownBox from './CountdownBox';
import PhaseStatus from './PhaseStatus';
import {
  ApproveBtn,
  BaseBtn,
  ConnectWalletBtn,
  EPhaseStatus,
  LoadingBtn,
  onError,
  TOKEN_DECIMALS,
} from '../Mint';
import { useActiveWeb3React } from '../../../hooksUniswap';
import { setIsOpenModalConnectWallet } from '../../../store/user';
import { useDispatch } from 'react-redux';
import config from '../../../config';
import { NumericFormat } from 'react-number-format';
import {
  ApprovalState,
  useApproveCallback,
} from '../../../hooksUniswap/useApproveCallback';
import { convertDecToWei } from '../../../utils/format';
import { Token, TokenAmount } from '@blast/v2-sdk';
import { ModalTransactionProcess } from '../../../modals';
import { useTokenBalance } from '../../../store/hooks/wallet';
import BigNumber from 'bignumber.js';
import { useBurnTokenCallback } from '../../../hooksUniswap/useMintNftCallback';
import About from './About';

const BURN_CONTRACT_ADDRESS = config.contracts.burn_contract;
const TOKEN_ADDRESS = config.contracts.burn_token_contract;

const BurnToken: FC<any> = ({ phaseData, setParams, params }) => {
  const { phaseStatus, dueDate } = phaseData;
  const [amount, setAmount] = useState<number | string | undefined>(undefined);
  const [isShowModalTransaction, setIsShowModalTransaction] =
    useState<boolean>(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState<boolean>(false);
  const [txHashInfo, setTxHashInfo] = useState<{
    hash: string;
    errorMessage?: string;
  }>();
  const [userTokenBalance, setUserTokenBalance] = useState<string>('0');

  const dispatch = useDispatch();
  const { account, chainId } = useActiveWeb3React();

  const onConnectWallet = () => {
    dispatch(setIsOpenModalConnectWallet(true));
  };

  const token = new Token(chainId as number, TOKEN_ADDRESS, TOKEN_DECIMALS);

  const { balance: userBalanceWei, isLoading } = useTokenBalance(
    account ?? undefined,
    token,
  );

  useEffect(() => {
    if (userBalanceWei?.toSignificant()) {
      setUserTokenBalance(userBalanceWei?.toSignificant());
    }
  }, [userBalanceWei]);

  const totalBurnAmountWei = useMemo(
    () => convertDecToWei(amount, TOKEN_DECIMALS),
    [amount],
  );

  const amountToApprove = useMemo(() => {
    return new TokenAmount(token, totalBurnAmountWei.toString());
  }, [amount]);

  const [approval, approveCallback] = useApproveCallback(
    amountToApprove,
    BURN_CONTRACT_ADDRESS,
  );

  const onApprove = async () => {
    if (approval !== ApprovalState.APPROVED) {
      setIsLoadingConfirm(true);
      setIsShowModalTransaction(true);
      try {
        const hash = await approveCallback();
        await new Promise((r) => setTimeout(r, config.block_time * 1000));
        setIsLoadingConfirm(false);
        setTxHashInfo({ hash });
      } catch (error: any) {
        setIsLoadingConfirm(false);
        setTxHashInfo((prev: any) => ({
          ...prev,
          errorMessage: error.message,
        }));
      }
    }
  };

  const [burnCallback] = useBurnTokenCallback(
    BURN_CONTRACT_ADDRESS,
    totalBurnAmountWei,
  );

  const onBurn = async () => {
    if (Number(amount) === 0) {
      return;
    }

    if (new BigNumber(userTokenBalance).comparedTo(amount || 0) < 0) {
      return onError({
        description: "You don't have sufficient balance",
      });
    }

    setIsLoadingConfirm(true);
    setIsShowModalTransaction(true);

    const onBurnHandle = () => {
      return burnCallback()
        .then(async (hash) => {
          await new Promise((r) => setTimeout(r, 5 * config.block_time * 1000));
          setIsLoadingConfirm(false);
          setTxHashInfo({ hash });
          setAmount(undefined);
          setParams({ ...params });
        })
        .catch((error) => {
          setIsLoadingConfirm(false);
          setTxHashInfo((prev: any) => ({
            ...prev,
            errorMessage: error.message,
          }));
        });
    };

    return onBurnHandle();
  };

  const onAddMax = () => {
    if (phaseStatus === EPhaseStatus.COMPLETED || !+userTokenBalance) return;
    setAmount(userTokenBalance);
  };

  const _renderButton = () => {
    if (isLoading) {
      return <LoadingBtn isLoading={isLoading} />;
    }

    if (!account) {
      return (
        <div className="MintPage__mint__info__connect-wallet">
          <ConnectWalletBtn onConnectWallet={onConnectWallet} />
          <div className="MintPage__mint__info__connect-wallet__warning">
            Please Connect your Wallet before burning
          </div>
        </div>
      );
    }

    if (approval !== ApprovalState.APPROVED) {
      return (
        <ApproveBtn
          isActive={phaseStatus !== EPhaseStatus.LIVE}
          approveState={approval}
          onApprove={onApprove}
        />
      );
    }

    return (
      <BaseBtn
        action={{
          text: 'BURNNNNN',
          disabled: phaseStatus !== EPhaseStatus.LIVE || !amount,
          onClick: onBurn,
        }}
      />
    );
  };

  return (
    <div className="MintPage__mint__info">
      <div className="MintPage__mint__info__noti">
        {phaseStatus === EPhaseStatus.COMING_SOON
          ? 'BURNING IS NOT START YET'
          : 'BURNING NOW'}
      </div>

      <div className="MintPage__mint__info__header">
        <div className="MintPage__mint__info__header__phase">
          Phase 1: Burn Tokens
          <div className="MintPage__mint__info__header__status">
            <PhaseStatus status={phaseStatus} />
          </div>
        </div>
      </div>

      <div className="MintPage__mint__info__description">
        Set those tokens on fire to grab your spot in the NFT minting round.
        Just the top 2800 homies burning the most tokens will get to mint their
        own P@cman NFT Gen1. Burn it up, fam!
      </div>

      <About />

      {dueDate && (
        <div className="MintPage__mint__info__countdown--coming-soon">
          <div className="MintPage__mint__info__countdown--coming-soon__text">
            {phaseStatus === EPhaseStatus.COMING_SOON
              ? `Burn process will start in:`
              : 'Burn process will end in:'}
          </div>
          <CountDownBox phaseStatus={phaseStatus} dueDate={dueDate} />
        </div>
      )}
      <div style={{ width: '100%' }}>
        <div className="MintPage__mint__burn__balance">
          Balance: {userTokenBalance}
        </div>
        <div className="MintPage__mint__burn-input">
          <div className="MintPage__mint__burn-input__label">
            Burn Amount <span>*</span>
          </div>
          <div className="MintPage__mint__burn-input__field">
            <NumericFormat
              placeholder="0"
              disabled={phaseStatus === EPhaseStatus.COMPLETED}
              value={amount ?? ''}
              allowLeadingZeros
              allowNegative={false}
              thousandSeparator=","
              decimalScale={8}
              onValueChange={({ floatValue }) => {
                return setAmount(floatValue);
              }}
            />
            <div className="btn-max" onClick={onAddMax}>
              Max
            </div>
            <div>$PACM</div>
          </div>
        </div>
      </div>

      <div className="MintPage__mint__info__mint-amount">{_renderButton()}</div>

      <ModalTransactionProcess
        isLoading={isLoadingConfirm}
        onClose={() => {
          setIsShowModalTransaction(false);
          setTxHashInfo({
            hash: '',
            errorMessage: '',
          });
        }}
        open={isShowModalTransaction}
        txHashInfo={txHashInfo}
      />
    </div>
  );
};

export default BurnToken;
