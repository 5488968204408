import React from 'react';
import { BasePage } from '../../layouts';
import Banner from './Banner';
import ListNFT from './ListNFT';
import '../../styles/pages/Marketplace.scss';

const Marketplace = () => {
  return (
    <BasePage>
      <div className="marketplace">
        <Banner />
        <ListNFT />
      </div>
    </BasePage>
  );
};

export default Marketplace;
