import React, { ReactNode, FC } from 'react';
import '../styles/layouts/BasePage.scss';
import { Header, Footer } from './index';
import {
  IconTwitter,
  IconTelegram,
  IconDiscord,
} from '../assets/icons';
import { ModalSubmitted, ModalSubmittingTransaction } from '../modals';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import {
  setIsOpenModalSubmitted,
  setDataTransaction,
} from '../store/transactions';
import { useDispatch } from 'react-redux';
import { useEffectUnsafe } from '../hooks/useEffectUnsafe';
import config from '../config';

interface IBasePage {
  children?: ReactNode;
  className?: string;
}

const BasePage: FC<IBasePage> = ({ children, className }) => {
  const { isOpenModalSubmitting, isOpenModalSubmitted } = useSelector(
    (state: RootState) => state.transactions,
  );

  const dispatch = useDispatch();
  const linkSocial = config.link;

  useEffectUnsafe(() => {
    if (isOpenModalSubmitting || isOpenModalSubmitted) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'inherit';
    }
  }, [isOpenModalSubmitting, isOpenModalSubmitted]);

  return (
    <>
      <Header />
      <div className={`main-content ${className}`}>
        <div>{children}</div>

        <div className="list-socials">
          <a href={linkSocial.twitter} target="_blank" title={'Twitter'}>
            <div className="btn-social">
              <IconTwitter />
            </div>
          </a>
          <a href={linkSocial.telegram} target="_blank" title={'Telegram'}>
            <div className="btn-social">
              <IconTelegram />
            </div>
          </a>
          {/*<a href={linkSocial.discord} target="_blank" title={'Discord'}>*/}
          {/*  <div className="btn-social">*/}
          {/*    <IconDiscord />*/}
          {/*  </div>*/}
          {/*</a>*/}
        </div>
      </div>
      <Footer className={`${className}`} />

      {isOpenModalSubmitting && (
        <ModalSubmittingTransaction open={isOpenModalSubmitting} />
      )}

      {isOpenModalSubmitted && (
        <ModalSubmitted
          open={isOpenModalSubmitted}
          onClose={() => {
            dispatch(setIsOpenModalSubmitted(false));
            dispatch(setDataTransaction({}));
          }}
        />
      )}
    </>
  );
};

export default BasePage;
