import AuthRequest from './AuthRequest';
import TowerRequest from './TowerRequest';
import EggCollectorRequest from './EggCollectorRequest';
import FundRequest from './FundRequest';
import QuestRequest from './QuestRequest';
import BurnRequest from './BurnRequest';
import NFTRequest from './NFTRequest';

const requestMap = {
  AuthRequest,
  TowerRequest,
  EggCollectorRequest,
  FundRequest,
  QuestRequest,
  BurnRequest,
  NFTRequest
};

const instances = {};

export default class RequestFactory {
  static getRequest(classname: string) {
    // @ts-ignore
    const RequestClass = requestMap[classname];
    if (!RequestClass) {
      throw new Error(`Invalid request class name: ${classname}`);
    }

    // @ts-ignore
    let requestInstance = instances[classname];
    if (!requestInstance) {
      requestInstance = new RequestClass();
      // @ts-ignore
      instances[classname] = requestInstance;
    }

    return requestInstance;
  }
}
