import BaseRequest from './BaseRequest';
import config from '../config';

export default class TowerRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.baseUrlApi;
  }

  getLeaderBoardTower(payload: any) {
    const url = `/tog/leader-board`;
    return this.get(url, payload);
  }

  getRecentBattleTower(payload: any) {
    const url = `/tog/recent-battle`;
    return this.get(url, payload);
  }

  getMyBattleTower(payload: any) {
    const url = `/tog/my-battle`;
    return this.get(url, payload);
  }
}
