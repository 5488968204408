import '@rainbow-me/rainbowkit/styles.css';
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import createRoutes from '../src/routes';
import configureStore from '../src/store';
import Web3ReactManager from './components/Web3ReactManager';
import { NetworkContextName } from './constants';
import ApplicationUpdater from './store/updater/application';
import MulticallUpdater from './store/updater/multicall';
import './styles/global.scss';
import getLibrary from './utils/swap/getLibrary';

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

function Updaters() {
  return (
    <>
      <ApplicationUpdater />
      <MulticallUpdater />
    </>
  );
}

/* eslint-disable-next-line */
function App() {
  const { store } = configureStore();

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <Provider store={store}>
          <Updaters />
          <Web3ReactManager>
            <BrowserRouter>
              {createRoutes()}
              <ToastContainer
                position="top-right"
                hideProgressBar
                pauseOnHover
                closeButton
                toastStyle={{
                  position: 'relative',
                  overflow: 'visible',
                }}
                theme="colored"
                toastClassName={'ToastCustom'}
                className={'ToastCustom__body'}
              />
            </BrowserRouter>
          </Web3ReactManager>
        </Provider>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  );
}

export default App;
