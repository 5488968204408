import React, { FC, useEffect, useState } from 'react';
import PacmanNFTGen0 from '../../abi/PacmanNFTGen0.json';
import PacmanNFTGen1 from '../../abi/PacmanNFTGen1.json';
import NftCard from '../../components/NftCard';
import config from '../../config';
import { MULTICALL_NETWORKS } from '../../constants/multicall';
import { useActiveWeb3React } from '../../hooksUniswap';
import {
  useGen1NftContract,
  useGen0NftContract,
} from '../../hooksUniswap/useContract';
import { BasePage } from '../../layouts';
import { multiCall } from '../../store/hooks/muilticall';
import '../../styles/pages/MyNftPage.scss';

const MyNftPage: FC = () => {
  const { account, chainId, library } = useActiveWeb3React();

  const nftGen1Contract = useGen1NftContract(
    config.contracts.gen1_nft_contract,
  );
  const nftGen0Contract = useGen0NftContract(
    config.contracts.gen0_nft_contract,
  );

  const getNFTs = async (
    nftContract: any,
    ABI: any,
    totalNFT: any,
    nftAddress: string,
  ) => {
    const data = [];
    for (let i = 0; i < totalNFT; i++) {
      data.push({
        address: nftContract?.address,
        method: 'tokenOfOwnerByIndex',
        params: [account, i],
      });
    }
    const results: any = await multiCall(
      library,
      chainId && MULTICALL_NETWORKS[chainId],
      ABI,
      data,
    );

    return await Promise.all(
      results?.map(async (item: any) => {
        return {
          url: await nftContract?.baseUri(),
          id: item?.toString(),
          address: nftAddress,
        };
      }),
    );
  };

  const [userNft, setUserNft] = useState<any>([]);

  const getUserNfts = async () => {
    if (!account) {
      return;
    }

    const nfts: any[] = [];

    const totalGen1Nft = await nftGen1Contract?.balanceOf(account);
    const totalGen0Nft = await nftGen0Contract?.balanceOf(account);

    if (totalGen1Nft > 0) {
      const dataGen1 = await getNFTs(
        nftGen1Contract,
        PacmanNFTGen1,
        totalGen1Nft,
        config?.contracts.gen1_nft_contract,
      ).then();

      nfts.push(...dataGen1);
    }

    if (totalGen0Nft > 0) {
      const dataGen0 = await getNFTs(
        nftGen0Contract,
        PacmanNFTGen0,
        totalGen0Nft,
        config?.contracts.gen0_nft_contract,
      ).then();

      nfts.push(...dataGen0);
    }

    setUserNft(nfts);
  };

  useEffect(() => {
    getUserNfts().then();
  }, [account]);

  return (
    <BasePage>
      <div className="MyNftPage">
        <div className="MyNftPage__title">My Nft</div>
        <div className="MyNftPage__nft-box">
          {userNft?.map(({ id, url, address }: any, index: number) => {
            return (
              <NftCard
                width={'224px'}
                key={index}
                src={url}
                nftId={id}
                address={address}
                fetchData={getUserNfts}
              />
            );
          })}
        </div>
      </div>
    </BasePage>
  );
};

export default MyNftPage;
