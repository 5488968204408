import React, { FC } from 'react';
import { BasePage } from '../../layouts';
import '../../styles/pages/MintPage.scss';
import Discover from './Discover';
import BurnMintNFT from './Mint';

const BurnMintNFTPage: FC = () => {
  return (
    <BasePage>
      <div className={'MintPage'}>
        <BurnMintNFT />
        <Discover />
      </div>
    </BasePage>
  );
};

export default BurnMintNFTPage;
