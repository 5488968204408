import BaseModal from './BaseModal';
import React, { FC, useEffect } from 'react';
import '../styles/modals/Modal.scss';
import { useWaitForTransaction } from 'wagmi';
import {
  setIsOpenModalSubmitted,
  setIsOpenModalSubmitting,
} from '../store/transactions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { toastError, toastSuccess } from '../utils/notify';
import { getErrorMessage } from '../utils/helper';
import { getBalancesUser } from '../store/user';
import config from '../config';

interface IModalSubmittingTransaction {
  open: boolean;
}

const ModalSubmittingTransaction: FC<IModalSubmittingTransaction> = ({
  open,
}) => {
  const { data } = useSelector((state: RootState) => state.transactions);
  const dispatch = useDispatch();
  const BLOCK_TIME = config.block_time;

  const { isSuccess, error } = useWaitForTransaction({
    confirmations: 9,
    hash: data?.hash,
    timeout: BLOCK_TIME * 10000,
  });

  useEffect(() => {
    if (isSuccess) {
      dispatch(setIsOpenModalSubmitted(true));
      dispatch(setIsOpenModalSubmitting(false));
      dispatch(getBalancesUser());
      toastSuccess('Successfully!');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      toastError(getErrorMessage(error));
    }
  }, [error]);

  return (
    <BaseModal open={open}>
      <div>
        <div className="modal__content">
          <div className="modal__content-title">Submitting Transaction</div>
          <div className="modal__content-description">
            Transaction is being submitted. <br /> It would take a minute, so
            take your time with coffee.
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default ModalSubmittingTransaction;
