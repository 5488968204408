import BaseRequest from './BaseRequest';
import config from '../config';

export default class FundRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.baseUrlApi;
  }

  createSignature(params: { amount: string }) {
    const url = `/funds/withdraw`;
    return this.post(url, params);
  }

  getTransactionsHistories(params: any) {
    const url = `/funds`;
    return this.get(url, params);
  }
}
