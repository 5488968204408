export default [
  {
    image: require('./post_1.png'),
    link: 'https://twitter.com/blast_degen/status/1763248507282935835',
  },
  {
    image: require('./post_2.png'),
    link: 'https://twitter.com/Kaffchad/status/1763219607744328075',
  },
  {
    image: require('./post_3.png'),
    link: 'https://twitter.com/AltcoinSherpa/status/1763211498552225935',
  },
  {
    image: require('./post_4.png'),
    link: 'https://twitter.com/RaAr3s/status/1763147935217242256',
  },
  {
    image: require('./post_5.png'),
    link: 'https://twitter.com/ShitcoinGemCall/status/1763191855393706115',
  },
  {
    image: require('./post_6.png'),
    link: 'https://twitter.com/0xLuxeCrypto/status/1763190699816825227',
  },
  {
    image: require('./post_7.png'),
    link: 'https://twitter.com/heraclitus765/status/1763179523514708354',
  },
  {
    image: require('./post_8.png'),
    link: 'https://twitter.com/blazing420s/status/1763158615857189294',
  },
  {
    image: require('./post_9.png'),
    link: 'https://twitter.com/unclebobcrypto/status/1763133709350305825',
  },
  {
    image: require('./post_10.png'),
    link: 'https://twitter.com/KongBTC/status/1763115788536603002',
  },
  {
    image: require('./post_11.png'),
    link: 'https://twitter.com/huoshan007/status/1763126259847213390',
  },
  {
    image: require('./post_12.png'),
    link: 'https://twitter.com/blastronaut_eth/status/1762396536867258583',
  },
  {
    image: require('./post_13.png'),
    link: 'https://twitter.com/blast_degen/status/1760955973886521467',
  },
];
