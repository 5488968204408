import { FACTORY_ADDRESS, INIT_CODE_HASH, JSBI, Percent, Token } from '@blast/v2-sdk';
import { AbstractConnector } from '@web3-react/abstract-connector';
import { injected, portis, walletconnect, walletlink } from '../connectors';
import { Tags, TokenInfo, TokenList } from '@uniswap/token-lists';

type TagDetails = Tags[keyof Tags];
export interface TagInfo extends TagDetails {
  id: string;
}

export class WrappedTokenInfo extends Token {
  public readonly tokenInfo: TokenInfo;
  public readonly tags: TagInfo[];
  constructor(tokenInfo: TokenInfo, tags: TagInfo[]) {
    super(
      tokenInfo.chainId,
      tokenInfo.address,
      tokenInfo.decimals,
      tokenInfo.symbol,
      tokenInfo.name,
    );
    this.tokenInfo = tokenInfo;
    this.tags = tags;
  }
  public get logoURI(): string | undefined {
    return this.tokenInfo.logoURI;
  }
}

export enum ChainId {
  BLAST_SEPOLIA = 168587773,
  BLAST_MAINNET = 81457,
}

export const ROUTER_ADDRESS = '0x98994a9a7a2570367554589189dc9772241650f6';

console.log('ROUTER_ADDRESS', ROUTER_ADDRESS, FACTORY_ADDRESS, 'INIT_CODE_HASH', INIT_CODE_HASH)

export const LP_TOKEN_NAME = 'P@cman Blastoff';
export const LP_TOKEN_SYMBOL = 'PACM';
// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[];
};

// export const DAI = new Token(ChainId.SEPOLIA, '0x7AF17A48a6336F7dc1beF9D485139f7B6f4FB5C8', 18, 'DAI', 'Dai Stablecoin')
// export const USDC = new Token(ChainId.SEPOLIA, '0x6f14C02Fc1F78322cFd7d707aB90f18baD3B54f5', 6, 'USDC', 'USD//C')
// export const USDT = new Token(ChainId.SEPOLIA, '0x7169d38820dfd117c3fa1f22a697dba58d90ba06', 6, 'USDT', 'Tether USD')

// export const DEX_TOKEN = new Token(
//   ChainId.BLAST_SEPOLIA,
//   '0x444Ab44A40feff726ed1248a8fd23eCa414Ad28C',
//   18,
//   LP_TOKEN_SYMBOL,
//   LP_TOKEN_NAME,
// );

const WETH = {
  [ChainId.BLAST_SEPOLIA]: '0x4200000000000000000000000000000000000023',
  [ChainId.BLAST_MAINNET]: '0x4300000000000000000000000000000000000004',
};

const WETH_ONLY: ChainTokenList = {
  [ChainId.BLAST_SEPOLIA]: [
    new WrappedTokenInfo(
      {
        chainId: ChainId.BLAST_SEPOLIA,
        address: '0x4200000000000000000000000000000000000022',
        decimals: 18,
        symbol: 'PACM',
        name: 'P@cman Blastoff',
        logoURI:
          'https://olive-hollow-coral-921.mypinata.cloud/ipfs/QmPNSPYuPFAE887QbUhUH1hyqDtX3Ui86322eauJ6rJKgZ',
      },
      [],
    ),
  ],
  [ChainId.BLAST_MAINNET]: [
    new WrappedTokenInfo(
      {
        chainId: ChainId.BLAST_MAINNET,
        address: '0x4200000000000000000000000000000000000023',
        decimals: 18,
        symbol: 'WETH',
        name: 'Wrapped Ether',
        logoURI:
          'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
      },
      [],
    ),
  ],
};
// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  // ...WETH_ONLY,
  [ChainId.BLAST_SEPOLIA]: [
    ...WETH_ONLY[ChainId.BLAST_SEPOLIA],
    //  DAI, USDC, USDT
  ],
  [ChainId.BLAST_MAINNET]: [
    new WrappedTokenInfo(
      {
        chainId: ChainId.BLAST_MAINNET,
        address: '0x0B4d0ee29857c3961b380d4ec138EA5814E346b9',
        decimals: 18,
        symbol: 'PACM',
        name: 'P@cman Blastoff',
        logoURI: 'https://olive-hollow-coral-921.mypinata.cloud/ipfs/QmPNSPYuPFAE887QbUhUH1hyqDtX3Ui86322eauJ6rJKgZ'
      },
      []
    ),
    new WrappedTokenInfo(
      {
        chainId: ChainId.BLAST_MAINNET,
        address: '0x4300000000000000000000000000000000000004',
        decimals: 18,
        symbol: 'WETH',
        name: 'Wrapped Ether',
        logoURI:
          'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png'
      },
      []
    )
  ]
};

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: {
  [chainId in ChainId]?: { [tokenAddress: string]: Token[] };
} = {};

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  // ...WETH_ONLY,
  [ChainId.BLAST_SEPOLIA]: [
    ...WETH_ONLY[ChainId.BLAST_SEPOLIA],
    // DAI, USDC, USDT,
  ],
  [ChainId.BLAST_MAINNET]: [
    new WrappedTokenInfo(
      {
        chainId: ChainId.BLAST_MAINNET,
        address: '0x0B4d0ee29857c3961b380d4ec138EA5814E346b9',
        decimals: 18,
        symbol: 'PACM',
        name: 'P@cman Blastoff',
        logoURI: 'https://olive-hollow-coral-921.mypinata.cloud/ipfs/QmPNSPYuPFAE887QbUhUH1hyqDtX3Ui86322eauJ6rJKgZ'
      },
      []
    ),
    new WrappedTokenInfo(
      {
        chainId: ChainId.BLAST_MAINNET,
        address: '0x4300000000000000000000000000000000000004',
        decimals: 18,
        symbol: 'WETH',
        name: 'Wrapped Ether',
        logoURI:
          'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png'
      },
      []
    )
  ]
};

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  // ...WETH_ONLY,
  [ChainId.BLAST_SEPOLIA]: [
    ...WETH_ONLY[ChainId.BLAST_SEPOLIA],
    //  DAI, USDC, USDT,
  ],
  [ChainId.BLAST_MAINNET]: [
    new WrappedTokenInfo(
      {
        chainId: ChainId.BLAST_MAINNET,
        address: '0x0B4d0ee29857c3961b380d4ec138EA5814E346b9',
        decimals: 18,
        symbol: 'PACM',
        name: 'P@cman Blastoff',
        logoURI: 'https://olive-hollow-coral-921.mypinata.cloud/ipfs/QmPNSPYuPFAE887QbUhUH1hyqDtX3Ui86322eauJ6rJKgZ'
      },
      []
    ),
    new WrappedTokenInfo(
      {
        chainId: ChainId.BLAST_MAINNET,
        address: '0x4300000000000000000000000000000000000004',
        decimals: 18,
        symbol: 'WETH',
        name: 'Wrapped Ether',
        logoURI:
          'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png'
      },
      []
    )
  ]
};

export const PINNED_PAIRS: {
  readonly [chainId in ChainId]?: [Token, Token][];
} = {
  [ChainId.BLAST_SEPOLIA]: [
    // [USDC, USDT],
    // [DAI, USDT]
  ],
};

export interface WalletInfo {
  connector?: AbstractConnector;
  name: string;
  iconName: string;
  description: string;
  href: string | null;
  color: string;
  primary?: true;
  mobile?: true;
  mobileOnly?: true;
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true,
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true,
  },
  WALLET_LINK: {
    connector: walletlink,
    name: 'Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
    color: '#315CF5',
  },
  COINBASE_LINK: {
    name: 'Open in Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Open in Coinbase Wallet app.',
    href: 'https://go.cb-w.com/mtUDhEZPy1',
    color: '#315CF5',
    mobile: true,
    mobileOnly: true,
  },

  Portis: {
    connector: portis,
    name: 'Portis',
    iconName: 'portisIcon.png',
    description: 'Login using Portis hosted wallet',
    href: null,
    color: '#4A6C9B',
    mobile: true,
  },
};

export const NetworkContextName = 'NETWORK';

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50;
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20;

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000));
export const BIPS_BASE = JSBI.BigInt(10000);
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(
  JSBI.BigInt(100),
  BIPS_BASE,
); // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(
  JSBI.BigInt(300),
  BIPS_BASE,
); // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(
  JSBI.BigInt(500),
  BIPS_BASE,
); // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(
  JSBI.BigInt(1000),
  BIPS_BASE,
); // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(
  JSBI.BigInt(1500),
  BIPS_BASE,
); // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(
  JSBI.BigInt(10),
  JSBI.BigInt(16),
); // .01 ETH
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(
  JSBI.BigInt(75),
  JSBI.BigInt(10000),
);
