import React, { FC, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Phase1Image,
  Phase2Image,
  BurnTokenImage,
  CompletedImage,
} from '../../assets/images';
import { WrapButton } from '../../components';
import config from '../../config';
import { ApprovalState } from '../../hooksUniswap/useApproveCallback';
import '../../styles/pages/MintPage.scss';
import Completed from './components/Completed';
import Phase1 from './components/Phase1';
import Phase2 from './components/Phase2';
import BurnToken from './components/BurnToken';
import moment from 'moment';
import LeaderBoard from './components/LeaderBoard';
import rf from '../../services/RequestFactory';
import { useEffectUnsafe } from '../../hooks/useEffectUnsafe';

export const enum EPhaseStatus {
  COMING_SOON = 'COMING_SOON',
  LIVE = 'LIVE',
  COMPLETED = 'COMPLETED',
}

export const enum EPhase {
  BURN_TOKEN = 'BURN_TOKEN',
  PHASE1 = 'PHASE1',
  PHASE2 = 'PHASE2',
  COMPLETED = 'COMPLETED',
}

export const TOKEN_DECIMALS = 18;
const phase = EPhase.PHASE2 as EPhase;

const BurnMintNFT: FC = () => {
  const [params, setParams] = useState<any>({});
  const [mintConfigs, setMintConfigs] = useState<any>({});

  const startTime = useMemo(() => {
    if (phase === EPhase.BURN_TOKEN)
      return config.mint_config[phase]?.startTime;
    if (phase === EPhase.PHASE1) return mintConfigs?.timeStartGen1;
    if (phase === EPhase.PHASE2) return mintConfigs?.timeStartGen0;
    return config.mint_config[phase]?.endTime;
  }, [mintConfigs, phase]);

  const endTime = useMemo(() => {
    if (phase === EPhase.BURN_TOKEN) return config.mint_config[phase]?.endTime;
    if (phase === EPhase.PHASE1) return mintConfigs?.timeEndGen1;
    if (phase === EPhase.PHASE2) return mintConfigs?.timeEndGen0;
    return config.mint_config[phase]?.endTime;
  }, [mintConfigs, phase]);

  const getMintConfig = async () => {
    try {
      const res = await rf.getRequest('BurnRequest').getMintConfigs();
      if (res) {
        setMintConfigs(res);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffectUnsafe(() => {
    if (phase === EPhase.BURN_TOKEN) return;
    getMintConfig().then();
  }, []);

  const phaseData = useMemo(() => {
    if (moment().utc().valueOf() < startTime) {
      return {
        phaseStatus: EPhaseStatus.COMING_SOON,
        dueDate: startTime,
      };
    }

    if (moment().utc().valueOf() > startTime && moment().utc().valueOf() < endTime) {
      return {
        phaseStatus: EPhaseStatus.LIVE,
        dueDate: endTime,
      };
    }

    return {
      phaseStatus: EPhaseStatus.COMPLETED,
    };
  }, [endTime, startTime]);

  const getImage = () => {
    if (phase === EPhase.PHASE1) {
      return Phase1Image;
    }
    if (phase === EPhase.PHASE2) {
      return Phase2Image;
    }

    if (phase === EPhase.COMPLETED) {
      return CompletedImage;
    }

    return BurnTokenImage;
  };

  return (
    <div className="MintPage__mint" style={{ position: 'relative' }}>
      <div className="MintPage__mint__main">
        <div className="MintPage__mint__image__container">
          <img
            className="MintPage__mint__image__container__img"
            src={getImage()}
          />
        </div>

        {phase === EPhase.BURN_TOKEN ? (
          <BurnToken
            phaseData={phaseData}
            setParams={setParams}
            params={params}
          />
        ) : phase === EPhase.PHASE1 ? (
          <Phase1
            phaseStatus={phaseData?.phaseStatus}
            dueDate={phaseData?.dueDate}
          />
        ) : phase === EPhase.PHASE2 ? (
          <Phase2
            phaseStatus={phaseData?.phaseStatus}
            dueDate={phaseData?.dueDate}
          />
        ) : (
          <Completed />
        )}
      </div>

      {phase === EPhase.BURN_TOKEN && <LeaderBoard params={params} />}
    </div>
  );
};

export default BurnMintNFT;

export const LoadingBtn = ({ isLoading }: any) => {
  return (
    <BaseBtn
      action={{
        text: 'Loading...',
        disabled: isLoading,
      }}
    />
  );
};

export const ConnectWalletBtn = ({ onConnectWallet }: any) => {
  return (
    <BaseBtn action={{ text: 'Connect Wallet', onClick: onConnectWallet }} />
  );
};

export const NotWhitelistedBtn = () => {
  return (
    <BaseBtn
      action={{
        text: `You're OUT OF TOP`,
        disabled: true,
        style: { backgroundColor: '#FF3979' },
      }}
    />
  );
};

export const ApproveBtn = ({ isActive, approveState, onApprove }: any) => {
  return (
    <BaseBtn
      action={{
        text:
          approveState === ApprovalState.PENDING ? 'Approving' : 'Approve PACM',
        disabled: approveState === ApprovalState.PENDING || isActive,
        onClick: onApprove,
      }}
    />
  );
};

export const MintBtn = ({ disabled, onMint }: any) => {
  return (
    <BaseBtn
      action={{
        text: 'FREE MINT NOW',
        disabled,
        onClick: onMint,
      }}
    />
  );
};

export const SoldOutBtn = () => {
  return (
    <WrapButton
      colorIcon="rgba(204, 204, 211, 0.5)"
      className="MintPage__mint__info__connect-wallet__btn MintPage__mint__info__connect-wallet__btn--sold-out"
    >
      <button
        style={{
          width: '100%',
          height: '100%',
          opacity: 1,
        }}
      >
        Sold out
      </button>
    </WrapButton>
  );
};

export const BaseBtn = ({ action }: any) => {
  return (
    <WrapButton className="MintPage__mint__info__connect-wallet__btn">
      <button
        onClick={action.onClick ?? undefined}
        style={{
          width: '100%',
          height: '100%',
          opacity: action.disabled ? 0.5 : 1,
          ...(action.style && action.style),
        }}
        disabled={action.disabled}
      >
        {action.text}
      </button>
    </WrapButton>
  );
};

export const onError = ({
  title,
  description,
}: {
  title?: string;
  description?: string;
}) => {
  toast.error(
    <div className="ToastCustom__container">
      {/*<div className="ToastCustom__icon">*/}
      {/*  <SadFace />*/}
      {/*</div>*/}
      <div className="ToastCustom__content">
        {!!title && <div className="ToastCustom__content__title">{title}</div>}
        {!!description && (
          <div className="ToastCustom__content__description">{description}</div>
        )}
      </div>
    </div>,
  );
};
