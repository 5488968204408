import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);

const VN_TIMEZONE = 'Asia/Ho_Chi_Minh';

export const getDiffDuration = (dueDate: Date | string) => {
  const timezone = VN_TIMEZONE;
  const clientDate = dayjs();
  return dayjs.duration(dayjs(dueDate, timezone).diff(clientDate.tz(timezone)));
};
