import React, { useState } from 'react';
import { FC } from 'react';
import '../../styles/pages/HomePage.scss';
import { IconDown } from '../../assets/icons';
import { ModalSelectToken } from '../../modals';
import { ICurrency } from '../../config';
import { formatNumber } from '../../utils/format';
import { isNumberDecimalAfter } from '../../utils/helper';
import { useActiveWeb3React } from '../../hooksUniswap';
import { useCurrencyBalance } from '../../store/hooks/wallet';
import { useEffectUnsafe } from '../../hooks/useEffectUnsafe';

interface ICurrencyInput {
  label: string;
  showMaxButton?: boolean;
  onMax?: () => void;
  onChange: (value: string) => void;
  // onSelectCurrency: (currency: ICurrency) => void;
  value: string;
  currency: ICurrency;
  otherCurrency: ICurrency;
  currencySelected: any;
}

const CurrencyInput: FC<ICurrencyInput> = ({
  label,
  showMaxButton,
  onMax,
  onChange,
  value,
  currency,
  // onSelectCurrency,
  otherCurrency,
  currencySelected,
}) => {
  // const [isShowModalSelectToken, setIsShowModalSelectToken] =
  //   useState<boolean>(false);

  const { account } = useActiveWeb3React();
  const selectedCurrencyBalance = useCurrencyBalance(
    account ?? undefined,
    currencySelected ?? undefined,
  );


  return (
    <div className="swap__box-input">
      <div className="swap__box-input--label">
        <div>{label}</div>
        {account && !!currencySelected && (
          <div>
            Balance:{' '}
            {formatNumber(selectedCurrencyBalance?.toSignificant(6) || 0, 8)}
          </div>
        )}
      </div>
      <div className="swap__box-input--field">
        <input
          value={value}
          placeholder={'0.0'}
          onChange={(e) => {
            const value = e.target.value;
            if (isNumberDecimalAfter(value) || value === '') {
              onChange(value);
            }
          }}
        />

        <div className="swap__box-input--field--right">
          {showMaxButton && (
            <div
              className="swap__btn-max"
              onClick={() => {
                onMax && onMax();
              }}
            >
              Max
            </div>
          )}

          <div
            className="swap__select-currency"
            // onClick={() => setIsShowModalSelectToken(true)}
          >
            {currency?.logoURI && (
              <div className="swap__select-currency-logo">
                <img src={currency?.logoURI} />
              </div>
            )}

            {!!Object.keys(currency).length ? (
              currency.symbol
            ) : (
              <span style={{ fontSize: '13px' }}>Select Token</span>
            )}
            {/*<IconDown />*/}
          </div>
        </div>
      </div>

      {/*{isShowModalSelectToken && (*/}
      {/*  <ModalSelectToken*/}
      {/*    onSelectCurrency={onSelectCurrency}*/}
      {/*    open={isShowModalSelectToken}*/}
      {/*    onClose={() => setIsShowModalSelectToken(false)}*/}
      {/*    otherCurrency={otherCurrency}*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  );
};

export default CurrencyInput;
