import { createSlice } from '@reduxjs/toolkit';

export type ApplicationState = {
  blockNumber: { [chainId: number]: number };
  blockTime: { [chainId: number]: number };
};

const initialState: ApplicationState = {
  blockNumber: {},
  blockTime: {},
};

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    updateBlockNumber: (state, action) => {
      const { chainId, blockNumber } = action.payload;
      if (typeof state.blockNumber[chainId] !== 'number') {
        state.blockNumber[chainId] = blockNumber;
      } else {
        state.blockNumber[chainId] = Math.max(
          blockNumber,
          state.blockNumber[chainId],
        );
      }
    },
  },
});

export const { updateBlockNumber } = applicationSlice.actions;

export default applicationSlice.reducer;
