import BaseModal from './BaseModal';
import React, { FC } from 'react';
import '../styles/modals/Modal.scss';
import { formatShortAddress } from '../utils/format';
import config from '../config';
import { IconClose, IconLink } from '../assets/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

interface IModalSubmitted {
  open: boolean;
  hash?: string;
  onClose: () => void;
}

const ModalSubmitted: FC<IModalSubmitted> = ({ open, onClose }) => {
  const BLOCK_EXPLORER = config.network_explorer;
  const { data } = useSelector((state: RootState) => state.transactions);

  return (
    <BaseModal open={open}>
      <div>
        <div className="modal__icon-close" onClick={onClose}>
          <IconClose />
        </div>

        <div className="modal__content">
          <div className="modal__content-title">Transaction Submitted!</div>
          <div className="modal__content-description">
            Transaction Hash:{' '}
            <a href={`${BLOCK_EXPLORER}/tx/${data?.hash}`} target={'_blank'}>
              {formatShortAddress(data?.hash)}
              <IconLink />
            </a>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default ModalSubmitted;
