import React, { FC } from 'react';
import { IconPacmanHeader, IconTelegram, IconTwitter } from '../assets/icons';
import config from '../config';

interface IFooter {
  className?: string;
}

const Footer: FC<IFooter> = ({ className }) => {
  const linkSocial = config.link;

  return (
    <div className={`footer ${className}`}>
      <div className="footer__bg-top"></div>

      <div className="footer__icon-left">
        <img src={'/images/home-page/icon-side-left.png'} />
      </div>
      <div className="footer__icon-right">
        <img src={'/images/home-page/icon-side-right.png'} />
      </div>

      <div className="footer__icon-bottom">
        <img src={'/images/footer/map-bottom.svg'} />
      </div>
      <div className="footer__content">
        <div className="footer__title">
          <div className="footer__title-item">
            <img src={'/images/footer/reel.svg'} />
            <img src={'/images/footer/image1.png'} />
            <img src={'/images/footer/today.svg'} />
          </div>
          <div className="footer__title-item">
            <img src={'/images/footer/trailblazer.svg'} />
            <img src={'/images/footer/image2.png'} />
          </div>
          <div className="footer__title-item">
            <img src={'/images/footer/image3.png'} />
            <img src={'/images/footer/tomorrow.svg'} />
          </div>
        </div>

        <div className="footer__social">
          <a href={linkSocial.twitter} target="_blank" title={'Twitter'}>
            <div className="footer__icon-social">
              <IconTwitter />
            </div>
          </a>
          <a href={linkSocial.telegram} target="_blank" title={'Telegram'}>
            <div className="footer__icon-social">
              <IconTelegram />
            </div>
          </a>
          {/*<a href={linkSocial.discord} target="_blank" title={'Discord'}>*/}
          {/*  <div className="footer__icon-social">*/}
          {/*    <IconDiscord />*/}
          {/*  </div>*/}
          {/*</a>*/}
        </div>

        <div className="footer__desc">
          $PACM is a community coin with no intrinsic value or expectation of
          financial return. There is no formal team or roadmap.
        </div>

        <div className="footer__menu">
          <div className="footer__logo">
            <IconPacmanHeader />
            P@CMAN
          </div>

          <div className="footer__text">
            <div>© P@cman Blastoff</div>
            {/*<div className="footer__privacy">Privacy</div>*/}
            {/*<div className="footer__privacy">Terms and Conditions</div>*/}
          </div>
        </div>
      </div>

      <a href={'https://airdrop.pacman.meme'} target={'_blank'}>
        <div className="image-air-drop">
          <img src={'/images/airdrop.gif'} />
        </div>
      </a>
    </div>
  );
};

export default Footer;
