import BaseRequest from './BaseRequest';
import config from '../config';

export default class BurnRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.burnUrlApi;
  }

  getLeaderBoard(payload: { limit: number; page: number }) {
    const url = `/burn-pacman/leaderboard`;
    return this.get(url, payload);
  }

  getBurner(address: string) {
    const url = `/burn-pacman/burner/${address}`;
    return this.get(url);
  }

  getMintConfigs() {
    const url = `/whitelist/minting-configs`;
    return this.get(url);
  }

  getSignatureGen1(address: string) {
    const url = `/whitelist/gen1/${address}/signature`;
    return this.get(url);
  }

  getSignatureGen0(address: string) {
    const url = `/whitelist/gen0/${address}/signature`;
    return this.get(url);
  }
}
