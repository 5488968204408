import { TransactionResponse } from '@ethersproject/providers';
import { useCallback } from 'react';
import { useTransactionAdder } from '../store/hooks/transaction';
import { calculateGasMargin } from '../utils/swap';
import { useNFTContract, useNFTMarketContract } from './useContract';

export const getPriceNFT = async (
  marketContract: any,
  nftContractAddr: any,
  tokenId: any,
) => {
  if (!marketContract) {
    console.error('nftContract is null');
    return 0;
  }

  const res = await marketContract?.getFinalPrice(nftContractAddr, tokenId);

  if (res[0]) {
    return res[0].toString();
  }

  return 0;
};

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useBuyNFTCallback(
  nftMarketContractAddress: string,
  nftAddress: any,
  id: string,
  price: string,
  currencyAddress: string,
): [() => Promise<string>] {
  const nftMarketContract = useNFTMarketContract(nftMarketContractAddress);
  const addTransaction = useTransactionAdder();

  const buyNFTCallback = useCallback(async (): Promise<string> => {
    if (!nftAddress || !currencyAddress) {
      console.error('nftAddress or currencyAddress  is null');
      return '';
    }

    if (!nftMarketContract) {
      console.error('nftContract is null');
      return '';
    }
    const estimatedGas = await nftMarketContract?.estimateGas?.purchaseListing(
      nftAddress,
      id,
      price,
      currencyAddress,
    );

    return nftMarketContract
      ?.purchaseListing(nftAddress, id, price, currencyAddress, {
        gasLimit: calculateGasMargin(estimatedGas),
      })
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: 'Buy NFT',
        });
        return response.hash;
      })
      .catch((error: any) => {
        if (error?.code === 4001 || error.code === 'ACTION_REJECTED') {
          throw new Error('Transaction rejected.');
        } else {
          // otherwise, the error was unexpected, and we need to convey that
          console.error(`Buy failed`, error);
          throw new Error(`Buy failed: ${error.message}`);
        }
      });
  }, [
    nftMarketContractAddress,
    nftAddress,
    id,
    price,
    currencyAddress,
    addTransaction,
  ]);

  return [buyNFTCallback];
}

export function useCancelListingCallback(
  nftMarketContractAddress: string,
  tokenAddress: any,
  id: string,
): [() => Promise<string>] {
  const nftMarketContract = useNFTMarketContract(nftMarketContractAddress);
  const addTransaction = useTransactionAdder();

  const cancelListingCallback = useCallback(async (): Promise<string> => {
    if (!nftMarketContract) {
      console.error('nftContract is null');
      return '';
    }

    const estimatedGas = await nftMarketContract?.estimateGas?.cancelListing(
      tokenAddress,
      id,
    );

    return nftMarketContract
      ?.cancelListing(tokenAddress, id, {
        gasLimit: calculateGasMargin(estimatedGas),
      })
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: 'Cancel Listing NFT',
        });
        return response.hash;
      })
      .catch((error: any) => {
        if (error?.code === 4001 || error.code === 'ACTION_REJECTED') {
          throw new Error('Transaction rejected.');
        } else {
          // otherwise, the error was unexpected, and we need to convey that
          console.error(`Cancel failed`, error);
          throw new Error(`Cancel failed: ${error.message}`);
        }
      });
  }, [nftMarketContractAddress, tokenAddress, id, addTransaction]);

  return [cancelListingCallback];
}

export function useAddListingCallback(
  nftMarketContractAddress: string,
  tokenAddress: string,
  id: string,
  price: string,
  currencyAddress: string,
  startTime: string | number,
  endTime: string | number,
): [() => Promise<string>] {
  const nftMarketContract = useNFTMarketContract(nftMarketContractAddress);
  const addTransaction = useTransactionAdder();

  const addListingCallback = useCallback(async (): Promise<string> => {
    if (!price) {
      console.error('price is null');
      return '';
    }

    if (!nftMarketContract) {
      console.error('nftContract is null');
      return '';
    }

    const estimatedGas = await nftMarketContract?.estimateGas?.addListing(
      tokenAddress,
      id,
      price,
      currencyAddress,
      startTime,
      endTime,
    );

    return nftMarketContract
      ?.addListing(
        tokenAddress,
        id,
        price,
        currencyAddress,
        startTime,
        endTime,
        {
          gasLimit: calculateGasMargin(estimatedGas),
        },
      )
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: 'Add Listing NFT',
        });
        return response.hash;
      })
      .catch((error: any) => {
        if (error?.code === 4001 || error.code === 'ACTION_REJECTED') {
          throw new Error('Transaction rejected.');
        } else {
          // otherwise, the error was unexpected, and we need to convey that
          console.error(`Add failed`, error);
          throw new Error(`Add failed: ${error.message}`);
        }
      });
  }, [
    nftMarketContractAddress,
    currencyAddress,
    tokenAddress,
    id,
    addTransaction,
    price,
  ]);

  return [addListingCallback];
}

export function useApproveNFT(
  nftContractAddress: string,
  nftMarketContractAddress: string,
): [() => Promise<string>] {
  const nftContract = useNFTContract(nftContractAddress);
  const addTransaction = useTransactionAdder();

  const approveNFTCallback = useCallback(async (): Promise<string> => {
    if (!nftContract) {
      console.error('nftContract is null');
      return '';
    }

    const estimatedGas = await nftContract?.estimateGas?.setApprovalForAll(
      nftMarketContractAddress,
      true,
    );

    return nftContract
      ?.setApprovalForAll(nftMarketContractAddress, true, {
        gasLimit: calculateGasMargin(estimatedGas),
      })
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: 'Approve NFT',
        });
        return response.hash;
      })
      .catch((error: any) => {
        if (error?.code === 4001 || error.code === 'ACTION_REJECTED') {
          throw new Error('Transaction rejected.');
        } else {
          // otherwise, the error was unexpected, and we need to convey that
          console.error(`Approve failed`, error);
          throw new Error(`Approve failed: ${error.message}`);
        }
      });
  }, [nftContractAddress, nftMarketContractAddress]);

  return [approveNFTCallback];
}
