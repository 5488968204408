import BaseModal from './BaseModal';
import React, { FC } from 'react';
import '../styles/modals/Modal.scss';
import { IconClose, IconWarning } from '../assets/icons';
import { ChainId, Trade } from '@blast/v2-sdk';
import { useActiveWeb3React } from '../hooksUniswap';
import { formatShortAddress } from '../utils/format';
import { getEtherscanLink } from '../utils/swap';

interface IModalTransactionSwap {
  open: boolean;
  isLoading: boolean;
  hash?: string;
  swapErrorMessage?: string;
  onClose: () => void;
  trade?: Trade;
}

const ModalTransactionSwap: FC<IModalTransactionSwap> = ({
  open,
  onClose,
  isLoading,
  swapErrorMessage,
  trade,
  hash,
}) => {
  const pendingText = `Swapping ${trade?.inputAmount?.toSignificant(6)} ${
    trade?.inputAmount?.currency?.symbol
  } for ${trade?.outputAmount?.toSignificant(6)} ${
    trade?.outputAmount?.currency?.symbol
  }`;

  const { chainId } = useActiveWeb3React();

  const _renderContent = () => {
    if (isLoading) {
      return (
        <div className="modal__content">
          <div className="modal__content-title">Waiting For Transaction</div>
          <div className="modal__content-description">{pendingText}</div>
          <div className="modal__content-description">
            Confirm this transaction in your wallet
          </div>
        </div>
      );
    }

    if (!!swapErrorMessage) {
      return (
        <div className="modal__content">
          <div className="modal__content-title">Error</div>
          <div>
            <IconWarning />
          </div>
          <div className="modal__content-description">{swapErrorMessage}</div>

          <div className="modal__list-btn">
            <div className="modal__btn-outline" onClick={onClose}>
              Dismiss
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="modal__content">
        <div className="modal__content-title">Transaction Submitted</div>

        <div className="modal__content-description">
          View on Blastscan:{' '}
          <a
            className={'link'}
            href={getEtherscanLink(
              chainId || ChainId.BLAST_SEPOLIA,
              hash || '',
              'transaction',
            )}
            target={'_blank'}
          >
            {formatShortAddress(hash || '')}
          </a>
        </div>

        <div className="modal__list-btn" onClick={onClose}>
          <div className="modal__btn-outline">Close</div>
        </div>
      </div>
    );
  };

  return (
    <BaseModal open={open} isHideOverlay>
      <div className="modal-confirm-swap">
        <div className="modal__icon-close" onClick={onClose}>
          <IconClose />
        </div>

        {_renderContent()}
      </div>
    </BaseModal>
  );
};

export default ModalTransactionSwap;
