import { createSlice } from '@reduxjs/toolkit';
import {
  INITIAL_ALLOWED_SLIPPAGE,
  DEFAULT_DEADLINE_FROM_NOW,
} from '../constants';

export interface SerializedToken {
  chainId: number;
  address: string;
  decimals: number;
  symbol?: string;
  name?: string;
}

export interface SerializedPair {
  token0: SerializedToken;
  token1: SerializedToken;
}

export type UserSwapState = {
  // the timestamp of the last updateVersion action
  lastUpdateVersionTimestamp?: number;

  userDarkMode: boolean | null; // the user's choice for dark mode or light mode
  matchesDarkMode: boolean; // whether the dark mode media query matches

  userExpertMode: boolean;

  // user defined slippage tolerance in bips, used in all txns
  userSlippageTolerance: number;

  // deadline set by user in minutes, used in all txns
  userDeadline: number;

  tokens: {
    [chainId: number]: {
      [address: string]: SerializedToken;
    };
  };

  pairs: {
    [chainId: number]: {
      // keyed by token0Address:token1Address
      [key: string]: SerializedPair;
    };
  };

  timestamp: number;
};

const currentTimestamp = () => new Date().getTime();

function pairKey(token0Address: string, token1Address: string) {
  return `${token0Address};${token1Address}`;
}

const initialState: UserSwapState = {
  userDarkMode: null,
  matchesDarkMode: false,
  userExpertMode: false,
  userSlippageTolerance: INITIAL_ALLOWED_SLIPPAGE,
  userDeadline: DEFAULT_DEADLINE_FROM_NOW,
  tokens: {},
  pairs: {},
  timestamp: currentTimestamp(),
};

const userSwapSlice = createSlice({
  name: 'userSwap',
  initialState,
  reducers: {
    updateUserSlippageTolerance: (state, action) => {
      const { userSlippageTolerance } = action.payload;
      state.userSlippageTolerance = userSlippageTolerance;
      state.timestamp = currentTimestamp();
    },
    updateUserDeadline: (state, action) => {
      const { userDeadline } = action.payload;
      state.userSlippageTolerance = userDeadline;
      state.timestamp = currentTimestamp();
    },
    updateVersion: (state) => {
      if (typeof state.userSlippageTolerance !== 'number') {
        state.userSlippageTolerance = INITIAL_ALLOWED_SLIPPAGE;
      }

      // deadline isnt being tracked in local storage, reset to default
      // noinspection SuspiciousTypeOfGuard
      if (typeof state.userDeadline !== 'number') {
        state.userDeadline = DEFAULT_DEADLINE_FROM_NOW;
      }

      state.lastUpdateVersionTimestamp = currentTimestamp();
    },
    addSerializedToken: (state, { payload: { serializedToken } }) => {
      state.tokens[serializedToken.chainId] =
        state.tokens[serializedToken.chainId] || {};
      state.tokens[serializedToken.chainId][serializedToken.address] =
        serializedToken;
      state.timestamp = currentTimestamp();
    },
    removeSerializedToken: (state, { payload: { address, chainId } }) => {
      state.tokens[chainId] = state.tokens[chainId] || {};
      delete state.tokens[chainId][address];
      state.timestamp = currentTimestamp();
    },
    addSerializedPair: (state, { payload: { serializedPair } }) => {
      if (
        serializedPair.token0.chainId === serializedPair.token1.chainId &&
        serializedPair.token0.address !== serializedPair.token1.address
      ) {
        const chainId = serializedPair.token0.chainId;
        state.pairs[chainId] = state.pairs[chainId] || {};
        state.pairs[chainId][
          pairKey(serializedPair.token0.address, serializedPair.token1.address)
        ] = serializedPair;
      }
      state.timestamp = currentTimestamp();
    },
    removeSerializedPair: (
      state,
      { payload: { chainId, tokenAAddress, tokenBAddress } },
    ) => {
      if (state.pairs[chainId]) {
        // just delete both keys if either exists
        delete state.pairs[chainId][pairKey(tokenAAddress, tokenBAddress)];
        delete state.pairs[chainId][pairKey(tokenBAddress, tokenAAddress)];
      }
      state.timestamp = currentTimestamp();
    },
  },
});

export const {
  updateUserDeadline,
  updateUserSlippageTolerance,
  updateVersion,
  addSerializedToken,
  removeSerializedToken,
  addSerializedPair,
} = userSwapSlice.actions;

export default userSwapSlice.reducer;
