import { Trade, TradeType } from '@blast/v2-sdk';
import React, { useContext } from 'react';
import { Field } from '../../store/swap';
import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown,
} from '../../utils/swap/prices';
import { ONE_BIPS } from '../../constants';
import { Percent } from '@blast/v2-sdk';
import { useLastTruthy } from '../../hooksUniswap/useLast';

const FormattedPriceImpact = ({ priceImpact }: { priceImpact?: Percent }) => {
  return (
    <div>
      {priceImpact
        ? priceImpact.lessThan(ONE_BIPS)
          ? '<0.01%'
          : `${priceImpact.toFixed(2)}%`
        : '-'}
    </div>
  );
};

function TradeSummary({
  trade,
  allowedSlippage,
}: {
  trade: Trade;
  allowedSlippage: number;
}) {
  const { priceImpactWithoutFee, realizedLPFee } =
    computeTradePriceBreakdown(trade);
  const isExactIn = trade.tradeType === TradeType.EXACT_INPUT;
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(
    trade,
    allowedSlippage,
  );

  return (
    <>
      <div>
        <div className="swap__details--item">
          <div>{isExactIn ? 'Minimum received' : 'Maximum sold'}</div>
          <div>
            {isExactIn
              ? `${slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)} ${
                  trade.outputAmount.currency.symbol
                }` ?? '-'
              : `${slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4)} ${
                  trade.inputAmount.currency.symbol
                }` ?? '-'}
          </div>
        </div>
        <div className="swap__details--item">
          <div>Price Impact</div>
          <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
        </div>

        <div className="swap__details--item">
          <div>Liquidity Provider Fee</div>
          <div>
            {realizedLPFee
              ? `${realizedLPFee.toSignificant(4)} ${
                  trade.inputAmount.currency.symbol
                }`
              : '-'}
          </div>
        </div>
      </div>
    </>
  );
}

const AdvancedSwapDetails = ({
  trade,
  allowedSlippage,
}: {
  trade?: Trade;
  allowedSlippage: number;
}) => {
  const showRoute = Boolean(trade && trade.route.path.length > 2);

  return (
    <div>
      {trade && (
        <>
          <TradeSummary trade={trade} allowedSlippage={allowedSlippage} />
          {showRoute && (
            <div>
              <div>
                <div>Route</div>
                {/*<SwapRoute trade={trade} />*/}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const SwapDetails = ({
  trade,
  allowedSlippage,
  isConfirm,
}: {
  trade?: Trade;
  allowedSlippage: number;
  isConfirm?: boolean;
}) => {
  const lastTrade = useLastTruthy(trade);

  if (!Boolean(trade)) return <></>;

  return (
    <div className={`swap__details ${isConfirm ? 'confirm' : ''}`}>
      <AdvancedSwapDetails
        allowedSlippage={allowedSlippage}
        trade={trade ?? lastTrade ?? undefined}
      />
    </div>
  );
};

export default SwapDetails;
