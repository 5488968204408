import { TransactionResponse } from '@ethersproject/providers';
import { useCallback } from 'react';
import { useTransactionAdder } from '../store/hooks/transaction';
import { calculateGasMargin } from '../utils/swap';
import {
  useBurnTokenContract,
  useGen0NftContract,
  useGen1NftContract,
} from './useContract';

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useMintNftGen1Callback(
  nftContractAddress: string,
  signature: string | undefined,
): [() => Promise<string>] {
  const nftContract = useGen1NftContract(nftContractAddress);
  const addTransaction = useTransactionAdder();
  const mintCallback = useCallback(async (): Promise<string> => {
    if (!signature) {
      console.error('Signature is empty');
      return '';
    }

    if (!nftContract) {
      console.error('nftContract is null');
      return '';
    }
    const estimatedGas = await nftContract?.estimateGas?.safeMint(signature);

    return nftContract
      ?.safeMint(signature, {
        gasLimit: calculateGasMargin(estimatedGas),
      })
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: 'Mint Pacman NFT',
        });
        return response.hash;
      })
      .catch((error: any) => {
        if (error?.code === 4001 || error.code === 'ACTION_REJECTED') {
          throw new Error('Transaction rejected.');
        } else {
          // otherwise, the error was unexpected, and we need to convey that
          console.error(`Mint failed`, error);
          throw new Error(`Mint failed: ${error.message}`);
        }
      });
  }, [nftContractAddress, signature, addTransaction]);

  return [mintCallback];
}

export function useMintNftGen0Callback(
  nftContractAddress: string,
  signature: string | undefined,
): [() => Promise<string>] {
  const nftContract = useGen0NftContract(nftContractAddress);
  const addTransaction = useTransactionAdder();
  const mintCallback = useCallback(async (): Promise<string> => {
    if (!signature) {
      console.error('Signature is empty');
      return '';
    }

    if (!nftContract) {
      console.error('nftContract is null');
      return '';
    }

    const estimatedGas = await nftContract?.estimateGas?.safeMint(signature);

    return nftContract
      ?.safeMint(signature, {
        gasLimit: calculateGasMargin(estimatedGas),
      })
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: 'Mint Pacman NFT',
        });
        return response.hash;
      })
      .catch((error: any) => {
        if (error?.code === 4001 || error.code === 'ACTION_REJECTED') {
          throw new Error('Transaction rejected.');
        } else {
          // otherwise, the error was unexpected, and we need to convey that
          console.error(`Mint failed`, error);
          throw new Error(`Mint failed: ${error.message}`);
        }
      });
  }, [nftContractAddress, signature, addTransaction]);

  return [mintCallback];
}

export function useBurnTokenCallback(
  burnContractAddress: string,
  burnAmount: number | string | undefined,
): [() => Promise<string>] {
  const burnContract = useBurnTokenContract(burnContractAddress);
  const addTransaction = useTransactionAdder();
  const mintCallback = useCallback(async (): Promise<string> => {
    if (!burnAmount) {
      console.error('burnAmount is empty');
      return '';
    }

    if (!burnContract) {
      console.error('burnContract is null');
      return '';
    }

    const estimatedGas = await burnContract?.estimateGas?.burnPacman(
      burnAmount,
    );

    return burnContract
      ?.burnPacman(burnAmount, {
        gasLimit: calculateGasMargin(estimatedGas),
      })
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: 'Burn Token Pacman',
        });
        return response.hash;
      })
      .catch((error: any) => {
        if (error?.code === 4001 || error.code === 'ACTION_REJECTED') {
          throw new Error('Transaction rejected.');
        } else {
          // otherwise, the error was unexpected, and we need to convey that
          console.error(`Burn failed`, error);
          throw new Error(`Burn failed: ${error.message}`);
        }
      });
  }, [burnContract, burnAmount, addTransaction]);

  return [mintCallback];
}
