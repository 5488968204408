import React, { useMemo, useState } from 'react';
import { IconEye } from '../../assets/icons';
import { ModalBuyNFT, ModalTransactionProcess } from '../../modals';
import { convertWeiToDec, formatNumber } from '../../utils/format';
import BigNumber from 'bignumber.js';
import { useActiveWeb3React } from '../../hooksUniswap';
import config from '../../config';
import { useEffectUnsafe } from '../../hooks/useEffectUnsafe';

const NFT_GEN0_CONTRACT_ADDRESS = config.contracts.gen0_nft_contract;

const ItemNFT = ({ nft, priceNFTs, fetchData }: any) => {
  const [isShowModalBuy, setIsShowModalBuy] = useState<boolean>(false);
  const [isShowModalTransaction, setIsShowModalTransaction] =
    useState<boolean>(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState<boolean>(false);
  const [txHashInfo, setTxHashInfo] = useState<{
    hash: string;
    errorMessage?: string;
  }>();

  useEffectUnsafe(() => {
    if (isShowModalBuy || isShowModalTransaction) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'inherit';
    }
  }, [isShowModalBuy, isShowModalTransaction]);

  const { account } = useActiveWeb3React();

  const isSold = useMemo(
    () => new BigNumber(priceNFTs[nft?.nftId]).isZero(),
    [priceNFTs, nft],
  );

  const isOwnerNFT = useMemo(
    () => account === nft?.sellerAddress,
    [account, nft],
  );

  const priceCurrent = useMemo(() => {
    if (isSold) return '--';
    return formatNumber(convertWeiToDec(priceNFTs[nft?.nftId], 18), 2);
  }, [priceNFTs, nft]);

  const _renderText = () => {
    if (isSold) return 'Sold Out';
    if (isOwnerNFT) return 'delist NFT';
    return 'Buy Now';
  };

  const isNFTGen0 = useMemo(
    () => nft?.nftAddress === NFT_GEN0_CONTRACT_ADDRESS,
    [nft],
  );

  return (
    <div className="marketplace__nft">
      <div>
        <img src={nft?.metadata?.image} />
      </div>

      <div className="marketplace__nft__detail">
        <div className="marketplace__nft__name">
          P@CMAN {isNFTGen0 ? 'GEN0' : 'GEN1'} #{nft?.nftId}
        </div>
        <div className="marketplace__nft__price">
          <span>Price</span> {priceCurrent} PACM
        </div>

        <div
          className={`marketplace__nft__btn ${isSold ? 'sold' : ''}`}
          onClick={() => setIsShowModalBuy(true)}
        >
          <div>
            <span>{_renderText()}</span>
          </div>
          <div>
            <IconEye />
          </div>
        </div>
      </div>

      {isShowModalBuy && (
        <ModalBuyNFT
          isNFTGen0={isNFTGen0}
          isSold={isSold}
          isOwnerNFT={isOwnerNFT}
          nft={nft}
          fetchData={fetchData}
          priceCurrent={priceCurrent}
          setTxHashInfo={setTxHashInfo}
          setIsLoadingConfirm={setIsLoadingConfirm}
          setIsShowModalTransaction={setIsShowModalTransaction}
          onClose={() => setIsShowModalBuy(false)}
          open={isShowModalBuy}
          priceNFT={priceNFTs[nft?.nftId]}
        />
      )}

      {isShowModalTransaction && (
        <ModalTransactionProcess
          isLoading={isLoadingConfirm}
          onClose={() => {
            setIsShowModalTransaction(false);
            setTxHashInfo({
              hash: '',
              errorMessage: '',
            });
          }}
          open={isShowModalTransaction}
          txHashInfo={txHashInfo}
        />
      )}
    </div>
  );
};

export default ItemNFT;
