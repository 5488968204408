import BaseRequest from './BaseRequest';
import config from '../config';

export default class NFTRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.burnUrlApi;
  }

  getListNFT(params?: any) {
    const url = `/market/list-nfts`;
    return this.get(url, params);
  }

  getDistinctField() {
    const url = `/market/distinct-field`;
    return this.get(url);
  }
}
