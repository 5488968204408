import React from 'react';
import { FC } from 'react';
import '../../styles/pages/HomePage.scss';

const PartRoadMap: FC = () => {
  return (
    <div className="road-map">
      <div className="road-map__icon-left">
        <img src={'/images/home-page/icon-side-left.png'} />
      </div>
      <div className="road-map__title">roadmap</div>
      <div className="road-map__image desktop">
        <img src={'/images/home-page/road-map.png'} />
      </div>
      {/*<div className="road-map__image tablet">*/}
      {/*  <img src={'/images/home-page/road-map-mobile.png'} />*/}
      {/*</div>*/}
      <div className="road-map__image mobile">
        <img src={'/images/home-page/road-map-mobile.png'} />
      </div>
      <div className="road-map__icon-right">
        <img src={'/images/home-page/icon-side-right.png'} />
      </div>
    </div>
  );
};

export default PartRoadMap;
