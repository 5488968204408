import React from 'react';
import '../../styles/pages/HomePage.scss';
import {
  IconCherry,
  IconApple,
  IconApple2,
  IconDown,
} from '../../assets/icons';
import { TypeAnimation } from 'react-type-animation';
import { useTokenBalance } from '../../store/hooks/wallet';
import { Token } from '@blast/v2-sdk';
import config from '../../config';
import { useActiveWeb3React } from '../../hooksUniswap';
import { formatCommaNumber } from '../../utils/format';

const TOKEN_ADDRESS = config.contracts.burn_token_contract;
const TOKEN_DECIMALS = 18;
const BURN_LP_TXID =
  '0x071a564b010700fb4392ba9db8133d35028065f0fd0316cf9151802861bb649d';
const RENOUNCE_OWNERSHIP_TXID =
  '0x0212d0e90e346e88abcda241130f0de9e007a55c7fa131500955e5d26ed46cb8';
const ADDRESS_BURN = '0x000000000000000000000000000000000000dEaD';

const Banner = ({ handleScrollClick }: any) => {
  const { chainId } = useActiveWeb3React();
  const token = new Token(chainId as number, TOKEN_ADDRESS, TOKEN_DECIMALS);
  const { balance: userBalanceWei } = useTokenBalance(ADDRESS_BURN, token);

  return (
    <div className="banner">
      <div className="banner__cloud-left">
        <img src="/images/home-page/cloud-left.png" />
      </div>
      <div className="banner__cloud-right">
        <img src="/images/home-page/cloud-right.png" />
      </div>
      <div className="banner__top">
        <div className="banner__content-top">
          <div className="banner__content-top__image">
            <div className="banner__content-top__cloud-center">
              <img src="/images/home-page/cloud-banner.png" />
            </div>
            <a
              href={`https://blastscan.io/tx/${BURN_LP_TXID}`}
              target="_blank"
              className="banner__quote-left"
            >
              <div className="banner__quote-left__content">
                <img src="/images/home-page/quote-left.png" />
                <div className="banner__quote-left__desc">
                  <div>Burn LP TxID</div>
                  <div>{BURN_LP_TXID}</div>
                </div>
              </div>
            </a>
            <a
              href={`https://blastscan.io/tx/${RENOUNCE_OWNERSHIP_TXID}`}
              target="_blank"
              className="banner__quote-left right"
            >
              <div className="banner__quote-left__content">
                <img src="/images/home-page/quote-right.png" />
                <div className="banner__quote-left__desc">
                  <div>Renounce Ownership TxID</div>
                  <div>{RENOUNCE_OWNERSHIP_TXID}</div>
                </div>
              </div>
            </a>
            <img src="/images/home-page/hero-banner.png" />
          </div>
          <div className="banner__content-top__detail">
            <div className="banner__content-top--text">
              Calling all squad members! Burn $PACM, Refer your friend & Score our FREE NFT!
            </div>
            <div className="banner__token-burn">
              <a className='banner__token-burn' href='/mint' target='_blank' title='Burn Now'>
                <img src="/images/home-page/token-burn.png" />
                <div className="banner__token-burn__desc">
                  <div>Token Burned</div>
                  <div className="banner__token-burn__number">
                    {userBalanceWei?.toSignificant()
                      ? formatCommaNumber(userBalanceWei?.toSignificant())
                      : '--'}
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div className="banner__icon-down">
            <IconDown />
            <div>Continue</div>
          </div>
        </div>
      </div>

      <div className="banner__content">
        <img src="/images/home-page/packing.gif" />
        <div>
          <div className="banner__text">
            <div>P@cman Blastoff is here for</div>
            <IconCherry />
          </div>
          <div className="banner__text">
            <TypeAnimation
              sequence={[1200, 'ALL', 600, 'ALL OF', 600, 'ALL OF YOU']}
              wrapper="span"
            />
            , who bravely face
          </div>
          <div className="banner__text">
            <IconApple /> adversity and skepticism to
          </div>
          <div className="banner__text">forge new paths in uncharted</div>
          <div className="banner__text">
            territories.
            <IconApple2 />
          </div>
        </div>

        <div className="banner__btn" onClick={handleScrollClick}>
          <div className="banner__btn-icon-left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="9"
              viewBox="0 0 14 9"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.29094 0H0.960938V3.33333H4.29094V0ZM6.96094 2H10.2943V5.33334H13.6276V8.66668H6.96094V8.66667V5.33334V2Z"
                fill="white"
              />
            </svg>
          </div>
          Rebel Today, Trailblazer Tomorrow
          <div className="banner__btn-icon-right">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="7"
              viewBox="0 0 15 7"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.168 0H10.8359H3.83464H0.169271V3.33333H3.83464V3.33334H3.83333V6.66668H14.5V3.33334H11.168V0Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
