/* eslint-disable @typescript-eslint/no-explicit-any */
import prod from './prod.json';
import dev from './dev.json';

const env = process.env.REACT_APP_ENV || 'dev';
const configs: any = { prod, dev };
const config: Config = configs[env];

export interface ICurrency {
  name?: string;
  address?: string;
  symbol?: string;
  decimals?: number;
  chainId?: number;
  logoURI?: string;
}

export interface ITime {
  startTime: string;
  endTime: string;
}

export interface Config {
  auth: {
    domain: string;
    message: string;
  };
  api: {
    baseUrlApi: string;
    burnUrlApi: string;
  };
  link: {
    twitter: string;
    telegram: string;
    discord: string;
    dextools: string;
  };
  contracts: {
    burn_contract: string;
    burn_token_contract: string;
    gen0_nft_contract: string;
    gen1_nft_contract: string;
    pacman_token_contract: string;
    nft_market_contract: string;
  };
  mint_config: {
    [key: string]: ITime;
  };
  contractAddress: string;
  block_time: number;
  game_build_version: string;
  contract_address: '0x${string}';
  contract_address_dex: '0x${string}';
  token_address: '0x${string}';
  network_explorer: string;
  network: {
    id: number;
    name: string;
    network: string;
    nativeCurrency: {
      decimals: number;
      name: string;
      symbol: string;
    };
    rpcUrls: {
      public: {
        http: string[];
      };
      default: {
        http: string[];
      };
    };
    blockExplorers: {
      blastscan: {
        name: string;
        url: string;
      };
      default: {
        name: string;
        url: string;
      };
    };
  };
  tokens: ICurrency[];
}

export default config;
