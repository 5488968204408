import React, { ReactNode } from 'react';
import { FC } from 'react';

interface IWrapButton {
  children?: ReactNode;
  colorIcon?: string;
  className?: string;
}

const WrapButton: FC<IWrapButton> = ({
  children,
  colorIcon = '#ffffff',
  className,
}) => {
  return (
    <div className={`btn-wrap ${className}`}>
      <div className="btn-wrap__icon-left">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="9"
          viewBox="0 0 14 9"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.29094 0H0.960938V3.33333H4.29094V0ZM6.96094 2H10.2943V5.33334H13.6276V8.66668H6.96094V8.66667V5.33334V2Z"
            fill={colorIcon}
          />
        </svg>
      </div>
      <div className="btn-wrap__icon-right">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="7"
          viewBox="0 0 15 7"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.168 0H10.8359H3.83464H0.169271V3.33333H3.83464V3.33334H3.83333V6.66668H14.5V3.33334H11.168V0Z"
            fill={colorIcon}
          />
        </svg>
      </div>
      {children}
    </div>
  );
};

export default WrapButton;
